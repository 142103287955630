import "./PatientInfoCard.scss";
import React from "react";
import { Icon } from "components";
import { toAmericanDate, ageFromDOB } from "utils/helpers";
import { useViewport } from "utils/hooks";
import EmailIcon from "assets/icons/envelope.svg";
import BirthdayIcon from "assets/icons/cake-candles.svg";
import GenderIcon from "assets/icons/genderless.svg";
import { Patient, Group } from "types";
import { useTranslation } from "react-i18next";

interface PatientInfoCardProps {
  patient: Patient;
  showName?: boolean;
  patientGroups: Group[];
}

const PatientInfoCard = ({
  patient,
  showName = true,
  patientGroups,
}: PatientInfoCardProps) => {
  const { isDesktop } = useViewport();
  const { t } = useTranslation(["common"]);

  return (
    <div className="patient-info-card">
      <div className="patient-info-card__patient_info">
        <table>
          <tbody>
            {isDesktop && showName && (
              <tr className="patient-info-table-row">
                <td colSpan={2} className="patient-info-table-label">
                  <b>
                    {patient?.firstName} {patient?.lastName}
                  </b>
                </td>
              </tr>
            )}
            <tr className="patient-info-table-row">
              <td className="patient-info-table-label">
                <Icon src={EmailIcon} />
              </td>
              <td>{patient?.email}</td>
            </tr>
            {patient?.profile?.idNumber && (
              <tr className="patient-info-table-row">
                <td className="patient-info-table-label">
                  <Icon src="IdCard2" />
                </td>
                <td>{patient?.profile?.idNumber}</td>
              </tr>
            )}
            <tr className="patient-info-table-row">
              <td className="patient-info-table-label">
                <Icon src={BirthdayIcon} />
              </td>
              <td>
                {patient?.profile?.dob &&
                patient?.profile?.dob !== "undefined" &&
                patient?.profile?.dob !== "null" ? (
                  <>
                    {toAmericanDate(patient?.profile?.dob)}{" "}
                    <span className="light-text">
                      ({ageFromDOB(patient?.profile?.dob)})
                    </span>
                  </>
                ) : (
                  <i>{t("common_labels.not_set")}</i>
                )}
              </td>
            </tr>
            <tr className="patient-info-table-row">
              <td className="patient-info-table-label">
                <Icon src={GenderIcon} />
              </td>
              <td>
                {patient?.profile?.gender &&
                patient?.profile?.gender !== ("undefined" as string) ? (
                  <>{patient?.profile?.gender}</>
                ) : (
                  <i>{t("common_labels.not_set")}</i>
                )}
              </td>
            </tr>
            {patientGroups?.length > 0 && (
              <tr className="patient-info-table-row">
                <td className="patient-info-table-label">
                  <Icon src="Group" />
                </td>
                <td>
                  {patientGroups?.map((group) => (
                    <div key={group.uuid}>{group.name}</div>
                  ))}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PatientInfoCard;
