import { PURGE } from "redux-persist";

const initialState = {
  user: null,
  pinVerified: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_AUTH_USER":
      return {
        ...state,
        user: payload,
      };
    case "VERIFY_USER":
      return {
        ...state,
        user: { ...state.user, verified: true },
      };
    case "UPDATE_USER_PROFILE":
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    case "UPDATE_PATIENT_REMINDER_SETTINGS":
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    case "UPDATE_REMOVED_PRACTICE_ID":
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    case "UPDATE_PIN_VERIFICATION_TRUE":
      return {
        ...state,
        pinVerified: true,
      };
    case "UPDATE_PIN_VERIFICATION_FALSE":
      return {
        ...state,
        pinVerified: false,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
