import { useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useCurrentUser, useLogout } from "utils/hooks";
import { formatInput, formatJson, formatJsonForGraphQL } from "utils/helpers";
import useFetchTemplates from "./useFetchTemplates";
import { updateTemplateAction } from "store/actions/templates";
import { FieldOption, Section } from "types";

function formatOptions(options: FieldOption[]) {
  if (!options) return "";
  return formatInput(JSON.stringify(options));
}

//TODO: Differentiate keys for draft template, published templates and assigned templates

const useUpdateTemplate = () => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchPrivateTemplates, fetchPublicTemplates } = useFetchTemplates();
  const { user } = useCurrentUser();

  const submitUpdateTemplate = (args: any, isPublishing = false) => {
    if (!args) {
      return null;
    }

    setIsSubmitSuccessful(false);

    const sections: Section[] = args.content;
    const sectionsAsGraphQLInput = sections
      ?.map(
        (section: Section) =>
          `{
        assignmentId: "${section.assignmentId}",
        createdAt: ${section.createdAt},
        description: "${formatInput(section.description)}",
        fields: [${section.fields
          ?.map(
            (field: any) =>
              `{
                type: "${field?.type}",
                label: "${formatInput(field?.label)}",
                hint: ${
                  field?.hint === undefined
                    ? `""`
                    : `"${formatInput(field?.hint)}"`
                },
                placeholder: ${
                  field?.placeholder === undefined
                    ? `""`
                    : `"${formatInput(field?.placeholder)}"`
                },
                status: "${field?.status}",
                order: ${
                  typeof field?.order === "number"
                    ? field?.order
                    : `"${field?.order}"`
                },
                name: "${field?.name}",
                sectionId: "${field?.sectionId}",
                required: ${field?.required},
                min: ${field?.min === undefined ? `""` : `"${field?.min}"`},
                max: ${field?.max === undefined ? `""` : `"${field?.max}"`},
                minLabel: ${
                  field?.minLabel === undefined ? `""` : `"${field?.minLabel}"`
                },
                maxLabel: ${
                  field?.maxLabel === undefined ? `""` : `"${field?.maxLabel}"`
                },
                inlineValue: ${
                  field?.inlineValue === undefined
                    ? `""`
                    : `"${field?.inlineValue}"`
                },
                fileId: ${
                  field?.fileId === undefined ? `""` : `"${field?.fileId}"`
                },
                videoURL: ${
                  field?.videoURL === undefined ? `""` : `"${field?.videoURL}"`
                },
                fileUrl: ${
                  field?.fileUrl === undefined ? `""` : `"${field?.fileUrl}"`
                },
                defaultValue: ${
                  field?.defaultValue === undefined
                    ? `""`
                    : `"${formatInput(field?.defaultValue)}"`
                },
                pinTitle: "${field?.pinTitle || ``}",
                pinToSection: "${field?.pinToSection || ``}",
                fromPrevious: ${field?.fromPrevious || false},
                fromPreviousField: "${
                  field?.fromPreviousField === undefined
                    ? ""
                    : formatInput(field?.fromPreviousField)
                }",
                usePrevious: ${field?.usePrevious || false},
                moodScale:"${formatJson(field?.moodScale)}",
                richText: "${formatInput(field?.richText)}",
                multiResponseOptions: "${formatJson(
                  field?.multiResponseOptions
                )}",
                checkboxOptions: "${formatJson(field.checkboxOptions)}",
                checkboxRows: "${formatJson(field.checkboxRows)}",
                options: ${
                  field?.options === undefined
                    ? `""`
                    : `"${formatOptions(field?.options)}"`
                },
                moods: ${
                  field?.moods === undefined ? `""` : `"${field?.moods}"`
                },
                icon: ${field?.icon === undefined ? `""` : `"${field?.icon}"`},
                colorTheme: ${
                  field?.colorTheme === undefined
                    ? `""`
                    : `"${field?.colorTheme}"`
                },
                allowMulti: ${field.allowMulti || false},
                fieldSetId: ${
                  field?.fieldSetId === undefined
                    ? `""`
                    : `"${field?.fieldSetId}"`
                },
                showCheckboxScoring: ${field.showCheckboxScoring || false},
                iconAlignment: ${
                  field?.iconAlignment === undefined
                    ? `""`
                    : `"${field?.iconAlignment}"`
                },
                scoreMappings: ${formatJsonForGraphQL(field?.scoreMappings)},
                includeInScoreCalculation: ${
                  field.includeInScoreCalculation || false
                },            
              }`
          )
          .join(", ")}],
        label: "${formatInput(section.label)}",
        name: "${formatInput(section.name)}",
        order: "${section.order}",
        slug: "${section.slug}",
        status: "${section.status}",
        theme: "${section.theme}",
        type: "${section.type}",
        uuid: "${section.uuid}",
        isDefaultStart: ${section.isDefaultStart || false}
      }`
      )
      .join(", ");

    const formattedTemplateTags =
      args.tags
        ?.map((t: any) => {
          return `{ 
            uuid: "${t.uuid || t.value.uuid}", 
            title: "${formatInput(t.title || t.value.title)}", 
            color: "${t.color || t.value.color}",
            type: "${t.type || t.value.type}",
            authorId: "${t.authorId || t.value?.authorId}"
          }`;
        })
        .join(", ") || [];

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                updateTemplate(template: {
                  title: "${formatInput(args.title)}",
                  description: "${formatInput(args.description)}",
                  instructions: "${formatInput(args.instructions)}",
                  content: [${sectionsAsGraphQLInput}],
                  status: "${args.status}",
                  category: "${args.category}",
                  assessmentScoring: ${formatJsonForGraphQL(
                    args.assessmentScoring
                  )},
                  uuid: "${args.uuid}", 
                  icon: {
                    name: "${args.icon?.name}",
                    src: "${args.icon?.src}"
                  },
                  color: "${args.color}",
                  tags: [${formattedTemplateTags}]
                }) {
                  title
                  description
                  instructions
                  authorId
                  slug
                  order
                  content
                  icon
                  color
                  uuid
                  status
                  category
                  assessmentScoring {
                    isScoringEnabled
                    scoringInstructions
                    scoringType
                    scoreSuffix
                    scoreAsPercentage
                    isFieldsetScoringEnabled
                    fieldSets {
                      id
                      name
                      fieldScoringType
                      order
                    }
                    scoreDisplays {
                      id
                      name
                      min
                      max
                      color
                      order
                    }
                  }
                  type
                  assignedCount
                  tags {
                    uuid
                    title
                    color
                    type
                    authorId
                  }
                  default
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "update_templates",
          },
        })
          .then(async (res) => {
            setIsSubmitSuccessful(true);
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                setIsSubmitSuccessful(true);
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                setIsSubmitSuccessful(true);
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              setIsSubmitSuccessful(true);
              resolve();
              return true;
            } else {
              fetchPrivateTemplates();
              fetchPublicTemplates();
              if (isPublishing) {
                toast("Your assignment template has been published");
              }
              setIsSubmitSuccessful(true);
              resolve();
              ReactGA.event("custom_assignment_updated_success", {
                user_id: user?.id,
                type: resData.data.updateTemplate.type,
                status: resData.data.updateTemplate.status,
              });
              return dispatch(
                updateTemplateAction(resData.data.updateTemplate)
              );
            }
          })
          .catch((err) => {
            setIsSubmitSuccessful(true);
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitUpdateTemplate,
    isSubmitSuccessful,
  };
};

export default useUpdateTemplate;
