import { TabItem } from "components/elements/Tabs/Tabs";
import { useState } from "react";

interface AssignmentDetailTabsProps {
  isInfosheet: boolean;
}

const useAssignmentDetailsTabs = ({
  isInfosheet,
}: AssignmentDetailTabsProps) => {
  const assignmentDetailTabs: TabItem[] = [
    {
      name: "Schedule",
      key: "schedule",
    },
    {
      name: "Configure",
      key: "configure",
    },
    {
      name: "Preview",
      key: "preview",
    },
    {
      name: "Fill In",
      key: "fillin",
    },
  ];
  const [activeTab, setActiveTab] = useState(
    assignmentDetailTabs[isInfosheet ? 0 : 1]
  );

  const handleTabChange = (tab: TabItem) => {
    setActiveTab(tab);
  };

  return {
    assignmentDetailTabs,
    activeTab,
    handleTabChange,
  };
};

export default useAssignmentDetailsTabs;
