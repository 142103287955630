import "./PatientDeleteConfirmationModal.styles.scss";
import React, { useState, useEffect } from "react";
import { Icon, Button, Checkbox } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";
import { useRemovePatient } from "utils/hooks";
import { Patient } from "types";
import { useTranslation } from "react-i18next";

interface PatientDeleteProps {
  patient: Patient | null;
  onCancel: () => void;
}

const PatientDeleteConfirmationModal = ({
  patient,
  onCancel,
}: PatientDeleteProps) => {
  const { removePatient } = useRemovePatient();
  const { t } = useTranslation(["common"]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isUnderstood, setIsUnderstood] = useState(false);

  useEffect(() => {
    if (isConfirmed && isUnderstood) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [isConfirmed, isUnderstood]);

  return (
    <div className="patient-deletion-confirmation">
      <div className="patient-deletion-confirmation__icon">
        <Icon src={WarningIcon} />
      </div>
      <div className="patient-deletion-confirmation__title">
        Are you sure you want to remove your connection to {patient?.firstName}{" "}
        {patient?.lastName}?
      </div>
      <div className="patient-deletion-confirmation__body">
        {patient?.profile?.status === "pending" ? (
          <span>
            Because {patient?.firstName} has not yet accepted your invite, they
            will be removed from our system. <br /> They can still sign up on
            their own. <br />
            Also, if they are added to the group, they will be removed.
          </span>
        ) : (
          <span>
            You will no longer be connected to {patient?.firstName} and you will
            no longer see submissions from them. <br />
            They can still access their assignments. <br />
            Also, if they are added to the group, they will be removed.
          </span>
        )}
      </div>
      <div className="patient-deletion-confirmation__checkboxes">
        <Checkbox
          name="understand"
          label={t("confirmations.confirm_delete_checkbox1")}
          ariaLabel={t("confirmations.confirm_delete_checkbox1")}
          onChange={(e) => setIsUnderstood(e.target.checked)}
        />
        <Checkbox
          name="confirm"
          label={t("confirmations.confirm_delete_checkbox2", {
            label: t("patients.patient_label"),
          })}
          ariaLabel={t("confirmations.confirm_delete_checkbox2", {
            label: t("patients.patient_label"),
          })}
          onChange={(e) => setIsConfirmed(e.target.checked)}
        />
      </div>
      <div className="patient-deletion-confirmation__buttons">
        <Button
          onClick={onCancel}
          name={`cancel_delete_patient_button`}
          value="Never mind"
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => {
            removePatient(patient);
            onCancel();
          }}
          name={`confirm_delete_patient_button`}
          value="Yes, remove them"
          extraClass="danger"
          size="small"
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default PatientDeleteConfirmationModal;
