export const convertSubmissionToData = (submission, assignment) => {
  const responseFieldsByFieldId = submission.responseFields.reduce(
    (acc, responseField) => {
      acc[responseField.fieldId] = responseField;
      return acc;
    },
    {}
  );

  return assignment.sections
    .reduce(
      (acc, section) => [
        ...acc,
        ...section.fields.map((field) => ({
          ...field,
          sectionOrder: section.order,
          sectionLabel: section.label,
        })),
      ],
      []
    )
    .sort((a, b) => a.sectionOrder - b.sectionOrder || a.order - b.order)
    .map((field) => ({
      field,
      response: responseFieldsByFieldId[field.uuid],
    }));
};
