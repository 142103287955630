import "./SinglePatient.styles.scss";
import React, { useState, useEffect } from "react";
import { Tabs, PageLoading, TopNav, SidePanel, Button, Icon } from "components";
import { useGetPatients, useFetchPatients, useGetGroups } from "utils/hooks";
import { useParams, useNavigate } from "react-router-dom";
import PatientInfoCard from "./components/PatientInfoCard/PatientInfoCard";
import useSinglePatientTabs from "./utils/useSinglePatientTabs";
import PatientChooser from "./components/PatientChooser/PatientChooser";
import PatientActionsMenu from "../Patients/components/PatientActionsMenu";
import PatientStatus from "./components/PatientStatus";
import { Patient } from "types";
import AddPatientsForm from "../Patients/components/AddPatientsForm";
import EditIcon from "assets/icons/pen-to-square.svg";
import ArrowBack from "assets/icons/arrow-back.svg";
// import VideoIcon from "assets/icons/video.svg";
import { truncateText } from "utils/helpers";

const SinglePatientPage: React.FC = () => {
  const { fetchAllPatients } = useFetchPatients();
  const { uuid } = useParams<{ uuid: string }>();
  const { patients } = useGetPatients({});
  const { groups } = useGetGroups({});

  const [currentPatient, setCurrentPatient] = useState<Patient | null>(null);

  const patientGroups = groups?.filter((group) =>
    group.groupMembersIds.includes(currentPatient?.uuid ?? "")
  );

  const navigate = useNavigate();

  const handlePatientChange = (patientId: string) => {
    const newPatient = patients?.find(
      (patient: Patient) => patient?.uuid === patientId
    );
    if (newPatient) {
      setCurrentPatient(newPatient);
      navigate(`/patients/${newPatient?.slug}/${activeTab?.url}`);
    }
  };

  const { tabItems, activeTab, handleTabChange } = useSinglePatientTabs();

  useEffect(() => {
    fetchAllPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (patients && patients.length > 0) {
      const patient = patients.find(
        (patient: Patient) => patient?.slug === uuid
      );
      setCurrentPatient(patient || null);
    }
  }, [patients, uuid]);

  const [isEditPatientVisible, setIsEditPatientVisible] = useState(false);

  const handleBackToPatients = () => {
    navigate(`/patients`);
  };

  if (!currentPatient) {
    return <PageLoading />;
  }

  return (
    <>
      <TopNav
        topL={
          <div
            className="single-patient__back pointer"
            onClick={() => handleBackToPatients()}
            onKeyDown={() => handleBackToPatients()}
            role="button"
            tabIndex={0}
          >
            <Icon src={ArrowBack} />
            <div>
              <h3>Back to Patients</h3>
            </div>
          </div>
        }
        botL={
          <PatientChooser
            currentPatient={currentPatient}
            onChange={(patientId: { value: string }) =>
              handlePatientChange(patientId?.value)
            }
          />
        }
        botR={
          <div className="single-patient-top-bar__actions">
            <PatientStatus
              status={currentPatient.profile.status}
              email={currentPatient.email}
            />
            <div className="ml-1">
              <Button
                onClick={() => {
                  setIsEditPatientVisible(true);
                }}
                name={`edit_patient`}
                value="Edit"
                extraClass="secondary"
                size="small"
                icon={EditIcon}
              />
            </div>
            <div className="flex align-center">
              {/* <Button
              onClick={() =>
                window.open(
                  `/session/${currentPatient.slug}`,
                  "_blank",
                  "rel=noopener noreferrer"
                )
              }
              name={`navigate_to_online_session_button`}
              value="Start Online Session"
              icon={VideoIcon}
              size="small"
              pill
            /> */}
              <PatientActionsMenu patient={currentPatient} />
            </div>
          </div>
        }
      />
      <div className="single-patient-top-area">
        <PatientInfoCard
          patient={currentPatient}
          showName={false}
          patientGroups={patientGroups}
        />
        <div className="single-patient-top-left">
          <div className="single-patient-tabs-top">
            {activeTab?.actionComponent}
            <Tabs
              responsive
              items={tabItems}
              activeTab={activeTab}
              setActiveTab={handleTabChange}
              size={"large"}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="single-patient-tabs-body">
          {activeTab.mainComponent}
        </div>
      </div>
      <SidePanel
        title={`Edit ${truncateText(
          currentPatient?.firstName ?? currentPatient.email
        )}'s Profile`}
        isVisible={isEditPatientVisible}
        onClose={() => setIsEditPatientVisible(false)}
      >
        <AddPatientsForm
          onSuccess={() => setIsEditPatientVisible(false)}
          patient={currentPatient}
        />
      </SidePanel>
    </>
  );
};

export default SinglePatientPage;
