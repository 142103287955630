import React from "react";
import "./PracticeLogo.styles.scss";

interface PracticeLogoProps {
  image: string;
}

const PracticeLogo = ({ image }: PracticeLogoProps) => {
  return (
    <div className="practice-logo-preview">
      <img src={image} alt={image} />
    </div>
  );
};

export default PracticeLogo;
