import "./ReminderDetails.styles.scss";
import React from "react";
import {
  toReadableTime,
  getDayDisplay,
  daysOfWeekFormatter,
} from "utils/helpers";
import BellIcon from "assets/icons/bell-on.svg";
import { Icon } from "components";

const ReminderDetails = ({ notification }) => {
  const { notificationTime, daysOfWeek } = notification;
  return (
    <div className="reminder-details">
      <span className="reminder-details__icon">
        <Icon src={BellIcon} alt="Bell Icon" />
      </span>
      {notificationTime.length > 0 &&
        notificationTime.map((time, index) => (
          <span className="reminder-details__time" key={index}>
            {toReadableTime(time)}
          </span>
        ))}
      <span className="reminder-details__days">
        {daysOfWeekFormatter(daysOfWeek)
          .sort((a, b) => a - b)
          .map((day, i) => {
            return (
              <span className="reminder-details__day" key={i}>
                {getDayDisplay(day).initials}
              </span>
            );
          })}
      </span>
    </div>
  );
};

export default ReminderDetails;
