import "./AddAssignmentsButton.styles.scss";
import React from "react";
import { Button } from "components";
import Plus from "assets/icons/circle-plus.svg";
import { useNavigate } from "react-router-dom";

const AddAssignmentsButton = ({ patientSlug }) => {
  const history = useNavigate();

  const navigateToAssignmentsLibrary = () => {
    history(`/resources/assignments/${patientSlug}`);
  };

  return (
    <Button
      name="add_assignment_button"
      value="Add Assignment"
      pill
      icon={Plus}
      size="small"
      onClick={navigateToAssignmentsLibrary}
    />
  );
};

export default AddAssignmentsButton;
