import "./SafetyPlanAddedContent.styles.scss";
import { Icon } from "components";
import React from "react";
import SafetyPlanIcon from "assets/icons/lifebuoy.svg";

const SafetyPlanAddedContent = ({ patient }) => {
  return (
    <div className="flex align-center">
      <div className="safety-icon-wrapper">
        <Icon src={SafetyPlanIcon} alt="Buoy Icon" />
      </div>
      <div>
        {patient?.firstName ?? patient.email} has a Safety Plan accessible via
        their mobile or web app. It can be used in times of crisis but is not a
        replacement for immediate intervention. Please make sure your patients
        fully understand what to do in case of emergency.
      </div>
    </div>
  );
};

export default SafetyPlanAddedContent;
