import React, { useMemo } from "react";
import { useGetPractices } from "utils/hooks";
import { Avatar, Icon, PracticeLogo } from "components";
import { User, Group } from "types";

type Props = {
  patient?: User | null;
  group?: Group | null;
  hidePatientInfo?: boolean;
};

const InfoSection = ({ patient, group, hidePatientInfo }: Props) => {
  const { practices } = useGetPractices({});

  const logo = useMemo(
    () => (practices && practices[0] ? practices[0].logoUrl : ""),
    [practices]
  );

  const getUserName = () => {
    const firstName = patient?.firstName ? `${patient?.firstName} ` : "";
    const lastName = patient?.lastName ? patient?.lastName : "";
    return firstName + lastName;
  };

  return (
    <div className="fill-modal_info">
      {logo && (
        <section className="fill-modal_info_practice-logo">
          <PracticeLogo image={logo} />
        </section>
      )}

      {!hidePatientInfo && (
        <>
          <section className="fill-modal_info_section">
            <div className="fill-modal_info_image">
              <div className="fill-modal_info_avatar">
                <Avatar user={patient} />
              </div>
            </div>
            <span className="fill-modal_info_name">
              {patient?.fullName ? patient.fullName : getUserName()}
            </span>
          </section>

          <section className="fill-modal_info_section">
            <div className="fill-modal_info_image">
              <div className="fill-modal_info_icon">
                <Icon src={"Envelope"} />
              </div>
            </div>
            <span className="fill-modal_info_email">{patient?.email}</span>
          </section>

          {patient?.profile?.idNumber && (
            <section className="fill-modal_info_section">
              <div className="fill-modal_info_image">
                <div className="fill-modal_info_icon">
                  <Icon src={"IdCard2"} />
                </div>
              </div>
              <span className="fill-modal_info_text">
                {patient.profile.idNumber}
              </span>
            </section>
          )}

          {group && (
            <section className="fill-modal_info_section">
              <div className="fill-modal_info_image">
                <div className="fill-modal_info_icon">
                  <Icon src={"Group2"} />
                </div>
              </div>
              <span className="fill-modal_info_text">{group?.name}</span>
            </section>
          )}
        </>
      )}
      <div className="fill-modal_info_brand">reflective</div>
    </div>
  );
};

export default InfoSection;
