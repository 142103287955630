import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useUserProfile } from "utils/hooks";
import {
  Button,
  Input,
  Select,
  Toggle,
  Modal,
  Avatar,
  SidePanel,
  Icon,
  ColorPicker,
  IconPicker,
} from "components";
import { useTranslation } from "react-i18next";
import TurnOff2FASettingConfirmation from "./components/TurnOff2FASettingConfirmation";
import { User } from "types";
import PencilIcon from "assets/icons/pencil.svg";
import { IconType } from "components/utility/IconPicker/IconPicker";
import { capitalizeFirstLetter } from "utils/helpers";
import SetPin from "./components/SetPin";

interface ProfileSettingsProps {
  user: User | null;
}

const ProfileSettings = ({ user }: ProfileSettingsProps) => {
  const { updateUserProfile, isProfileSubmitting } = useUserProfile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const languageOptions = [
    { label: "English (US)", value: "en" },
    { label: "עברית", value: "he" },
  ];

  const { register, handleSubmit, control, errors } = useForm();

  const mappedCurrentLanguage = {
    value: user?.defaultLanguage,
    label: languageOptions.find((l) => l.value === user?.defaultLanguage)
      ?.label,
  };

  const { t } = useTranslation(["common"]);

  const [currentDigestSetting, setCurrentDigestSetting] = useState(
    user?.digestSubscription
  );

  const [current2FASetting, setCurrent2FASetting] = useState(
    user?.is2FAEnabled || false
  );
  const [is2FAChanged, setIs2FAChanged] = useState(false);
  const [showColorIconPanel, setShowColorIconPanel] = useState(false);
  const [currentColor, setCurrentColor] = useState(user?.color);
  const [currentIcon, setCurrentIcon] = useState<IconType>({
    name: capitalizeFirstLetter(user?.icon),
    src: capitalizeFirstLetter(user?.icon),
  });
  const [pinCode, setPinCode] = useState(user?.pinCode ? user.pinCode : "");
  const [showSetPinPanel, setShowSetPinPanel] = useState(false);
  const [pinSetting, setPinSetting] = useState(user?.pinCode ? true : false);

  useEffect(() => {
    if (!showSetPinPanel && !pinCode) setPinSetting(false);
  }, [showSetPinPanel, pinCode]);

  const handleColorIconPanel = () => {
    setShowColorIconPanel(!showColorIconPanel);
  };

  const handleDigestSettingChange = () => {
    setCurrentDigestSetting(!currentDigestSetting);
  };

  const handle2FASettingChange = () => {
    if (current2FASetting) {
      setIsModalOpen(true);
    } else {
      setCurrent2FASetting(true);
      setIs2FAChanged(true);
    }
  };

  const handleTurnOff2FASetting = () => {
    setCurrent2FASetting(false);
    setIs2FAChanged(true);
    setIsModalOpen(false);
  };

  const handlePinSettingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPinSetting(e.target.checked);
    if (e.target.checked) setShowSetPinPanel(true);
    if (!e.target.checked) setPinCode("");
  };

  const handleClosePinPanel = () => {
    setShowSetPinPanel(false);
  };

  const onSubmit = (data: any) => {
    if (data) {
      updateUserProfile({
        ...data,
        is2FAEnabled: is2FAChanged ? current2FASetting : false,
        color: currentColor,
        icon: currentIcon.src,
        pinCode: pinCode,
      });
    }
  };

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const watchedFirstName = useWatch({ control, name: "firstName" });
  const watchedLastName = useWatch({ control, name: "lastName" });
  const watchedAlias = useWatch({ control, name: "alias" });
  const watchedLanguage = useWatch({ control, name: "defaultLanguage" }) as {
    label: string;
    value: string;
  };
  const watchedDigestSubscription = useWatch({
    control,
    name: "digestSubscription",
  });
  useEffect(() => {
    const watchedAliasFormatted =
      watchedAlias === undefined ? null : watchedAlias;
    if (
      watchedFirstName !== user?.firstName ||
      watchedLastName !== user?.lastName ||
      watchedAliasFormatted !== user?.alias ||
      watchedLanguage.value !== user?.defaultLanguage ||
      watchedDigestSubscription !== user?.digestSubscription ||
      current2FASetting !== user?.is2FAEnabled ||
      pinCode !== user?.pinCode
    ) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [
    watchedFirstName,
    watchedLastName,
    watchedAlias,
    watchedLanguage,
    watchedDigestSubscription,
    current2FASetting,
    pinCode,
    user,
  ]);

  return (
    <>
      <section className="settings-section" id="profile-settings">
        <h2 className="section-title">{t("profile.profile_settings_label")}</h2>
        <hr className="divider" />
        <div className="settings-section__content">
          <div
            className="settings-section__avatar-section pointer"
            onClick={handleColorIconPanel}
            onKeyDown={handleColorIconPanel}
            tabIndex={0}
            role="button"
          >
            <div className="settings-section__avatar-wrapper">
              <Avatar color={currentColor} icon={currentIcon.src} />
            </div>
            <div className="settings-section__avatar-edit-icon">
              <Icon src={PencilIcon} />
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="settings-section__content">
            <Input
              name="firstName"
              type="text"
              label={t("common_labels.first_name")}
              defaultValue={user?.firstName ?? undefined}
              placeholder="Peter"
              forwardRef={register({ required: true })}
              error={errors.firstName && t("profile.user_first_name_required")}
            />
            <Input
              name="lastName"
              type="text"
              label={t("common_labels.last_name")}
              defaultValue={user?.lastName ?? undefined}
              placeholder="Parker"
              forwardRef={register({ required: true })}
              error={errors.lastName && t("profile.user_last_name_required")}
            />
            {user?.type === "patient" && (
              <Input
                name="alias"
                label={t("common_labels.alias")}
                defaultValue={user ? user.alias : undefined}
                placeholder="batman"
                forwardRef={register({ required: false })}
                hint="Your alias, which is optional, will be used within anonymous groups and other places"
              />
            )}
            <Select
              name="defaultLanguage"
              label={t("common_labels.default_language")}
              forwardRef={register()}
              options={languageOptions}
              forwardControl={control}
              defaultValue={mappedCurrentLanguage}
              onChange={() => console.log("language changed")}
            />
            <Input
              name="email"
              type="email"
              label={t("common_labels.email")}
              disabled
              defaultValue={user ? user.email : undefined}
              placeholder="name@email.com"
              hint={
                <span>
                  {t("profile.change_email_address_prefix")}{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:support@reflectiveapp.com"
                  >
                    {t("profile.change_email_address_label")}
                  </a>
                  .
                </span>
              }
            />
            {user?.type === "therapist" && (
              <>
                <section className="settings-section__toggle-box">
                  <div className="flex align-center justify-between">
                    <h4 className="no-mt no-mb">
                      {t("profile.digest_settings_label")}
                    </h4>
                    <Toggle
                      name="digestSubscription"
                      id="digestSubscription"
                      checked={currentDigestSetting ? true : false}
                      forwardRef={register()}
                      onChange={handleDigestSettingChange}
                      onText="Yes"
                      offText="No"
                      isSmall={false}
                    />
                  </div>
                  <p className="mt-2">
                    <small>{t("profile.digest_settings_explainer")}</small>
                  </p>
                </section>
                {/* PIN setting */}
                <section className="settings-section__toggle-box">
                  <div className="flex align-center justify-between">
                    <h4 className="no-mt no-mb">
                      {t("profile.pin_settings_label")}
                    </h4>
                    <Toggle
                      name="pinSetting"
                      id="pinSetting"
                      checked={pinSetting}
                      onChange={handlePinSettingChange}
                      onText="Yes"
                      offText="No"
                      isSmall={false}
                    />
                  </div>
                  <p className="mt-2">
                    <small>{t("profile.pin_settings_explainer")}</small>
                  </p>
                </section>
              </>
            )}
            <div style={{ display: "none" }}>
              <h4 className="no-mt">{t("profile.2fa_settings_label")}</h4>
              <Toggle
                name="is2FAEnabled"
                id="is2FAEnabled"
                checked={current2FASetting ? true : false}
                forwardRef={register()}
                onChange={handle2FASettingChange}
                onText="On"
                offText="Off"
                isSmall={false}
              />
              <p className="mt-2">
                <small>{t("profile.2fa_settings_explainer")}</small>
              </p>
            </div>
            <Button
              type="submit"
              name="profile_settings_button"
              value={t("common_labels.submit_changes")}
              isLoading={isProfileSubmitting}
              disabled={!hasChanges || isProfileSubmitting}
              extraClass="pill big"
            />
          </div>
        </form>
        <hr className="divider" />
        <p>
          <small>{t("profile.reset_password_instructions_intro")}</small>
          <br />
          <small>{t("profile.reset_password_instructions_explainer")}</small>
        </p>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <TurnOff2FASettingConfirmation
            onTurnOff2FASetting={handleTurnOff2FASetting}
            onCancel={() => setIsModalOpen(false)}
          />
        </Modal>
      </section>
      <SidePanel
        title="Edit"
        isVisible={showColorIconPanel}
        onClose={handleColorIconPanel}
      >
        <>
          <ColorPicker
            selectedColor={currentColor}
            onColorChange={(c) => setCurrentColor(c)}
          />
          {user?.type === "patient" && (
            <IconPicker
              selectedIcon={currentIcon}
              onIconChange={(i: IconType) => setCurrentIcon(i)}
              hint="Icons are displayed in place of initials when required for anonymity"
            />
          )}
          <div className="mt-3">
            <Button
              name="avatar_settings_button"
              value={t("common_labels.submit_changes")}
              onClick={() => onSubmit(user)}
              isLoading={isProfileSubmitting}
              disabled={isProfileSubmitting}
              fullWidth
            />
          </div>
        </>
      </SidePanel>

      <SidePanel
        title={t("profile.create_pin_title")}
        isVisible={showSetPinPanel}
        onClose={handleClosePinPanel}
      >
        <SetPin setPinCode={setPinCode} onClose={handleClosePinPanel} />
      </SidePanel>
    </>
  );
};

export default ProfileSettings;
