import FileUploader from "components/utility/FileUploader/FileUploader";
import "./FileAttachmentField.styles.scss";
import React, { useContext, useState } from "react";
import FileCardCondensed from "pages/AppPages/TherapistPages/AssignmentBuilder/components/FieldOptionsForm/FileChooser/FileCardCondensed";
import { useGetFile } from "utils/hooks";
import { AssignmentBuilderContext } from "pages/AppPages/TherapistPages/AssignmentBuilder/useAssignmentBuilderContext";

interface FileAttachmentFieldProps {
  uuid: string | undefined;
  label: string;
  field: any;
  fileId?: string;
  showLabelForBuilder?: boolean;
  autoSave?: boolean;
  validationError?: string;
  fromBuilder?: boolean;
}

const FileAttachmentField = ({
  uuid,
  label,
  fileId,
  showLabelForBuilder,
  autoSave,
  field,
  validationError,
  fromBuilder,
}: FileAttachmentFieldProps) => {
  const [currentFileId, setCurrentFileId] = useState(fileId);
  const { file } = useGetFile({ fileId: currentFileId });

  const contextValue = useContext(AssignmentBuilderContext) || {};

  const { editField } = contextValue;

  return (
    <div key={uuid} className="file-field">
      {showLabelForBuilder && (
        <label className="file-field__label">{label}</label>
      )}
      {!currentFileId ? (
        <FileUploader
          showPatients={false}
          currentFile={file}
          onSuccess={(fileID) => {
            setCurrentFileId(fileID);
            autoSave
              ? editField({ ...field, uuid, label, fileId: fileID })
              : null;
          }}
          dropZoneOnly={true}
          error={validationError}
          fromBuilder={fromBuilder ? true : false}
        />
      ) : (
        <FileCardCondensed file={file} />
      )}
    </div>
  );
};

export default FileAttachmentField;
