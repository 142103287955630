import React, { useState, useEffect } from "react";
import SubmissionsIcon from "assets/icons/inbox.svg";
import PatientSubmissions from "../components/PatientSubmissions/PatientSubmissions";
import PatientLibrary from "../components/PatientLibrary/PatientLibrary";
import PatientWorkbook from "../components/PatientWorkbook/PatientWorkbook";
import CrisisIntervention from "../components/CrisisIntervention";
import { useNavigate, useParams } from "react-router-dom";
import PatientNotes from "../components/PatientNotes/PatientNotes";
import { useGetAssignments, useGetPatients, useQueryParams } from "utils/hooks";
import { TabItem } from "components/elements/Tabs/Tabs";
import { useTranslation } from "react-i18next";

const useSinglePatientTabs = () => {
  const { t } = useTranslation(["common"]);
  const history = useNavigate();
  const tabItems: TabItem[] = [
    {
      name: t("tab_titles.inbox_title"),
      url: "submissions",
      key: "submissions",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <PatientSubmissions />,
    },
    {
      name: t("tab_titles.workbook_title"),
      url: "workbook",
      key: "workbook",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <PatientWorkbook />,
    },
    {
      name: t("tab_titles.notes_title"),
      url: "notes",
      key: "notes",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <PatientNotes />,
    },
    {
      name: t("tab_titles.resources_title"),
      url: "resources",
      key: "resources",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <PatientLibrary />,
    },
    {
      name: t("tab_titles.crisis_intervention_title"),
      url: "intervention",
      key: "intervention",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <CrisisIntervention />,
    },
  ];

  const { urlParams } = useQueryParams();
  const { slug, uuid } = useParams<{ slug: string; uuid: string }>();
  const { patient } = useGetPatients({ slug: uuid });
  const { assignments } = useGetAssignments({
    patientId: patient?.uuid,
  });

  const getTabFromUrl = (): TabItem | undefined => {
    return tabItems.find((tab) => tab.url === slug);
  };

  const defaultTabIndex = assignments?.length > 0 ? tabItems[0] : tabItems[1];
  const defaultTab = getTabFromUrl() || defaultTabIndex;

  const [activeTab, setActiveTab] = useState<TabItem>(defaultTab);

  const handleTabChange = (tab: TabItem) => {
    history(`/patients/${uuid}/${tab?.url}`);
    setActiveTab(tab);
  };

  useEffect(() => {
    handleTabChange(defaultTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (activeTab) {
      history(
        `/patients/${uuid}/${activeTab.url}${urlParams ? `?${urlParams}` : ""}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, history, uuid]);

  return {
    tabItems,
    activeTab,
    handleTabChange,
  };
};

export default useSinglePatientTabs;
