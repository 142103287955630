const setAuthenticatedUser = (user) => ({
  type: "SET_AUTH_USER",
  payload: user,
});

const verifyAuthenticatedUser = () => ({
  type: "VERIFY_USER",
});

const updatePinVerificationTrue = () => ({
  type: "UPDATE_PIN_VERIFICATION_TRUE",
});

const updatePinVerificationFalse = () => ({
  type: "UPDATE_PIN_VERIFICATION_FALSE",
});

export {
  setAuthenticatedUser,
  verifyAuthenticatedUser,
  updatePinVerificationTrue,
  updatePinVerificationFalse,
};
