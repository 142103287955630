import { useState } from "react";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { updateUserProfileAction } from "store/actions/users";
import { toast } from "react-toastify";
import { useCurrentUser } from "utils/hooks";
import { User } from "types";
import { formatGraphQLValue } from "utils/helpers";

const useUserProfile = () => {
  const dispatch = useDispatch();
  const [isProfileSubmitting, setIsProfileSubmitting] = useState(false);
  const { user, isPatient } = useCurrentUser();

  const updateUserProfile = (args: User) => {
    if (!args || !user) {
      return null;
    }

    return new Promise(() => {
      setTimeout(() => {
        setIsProfileSubmitting(true);
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                updateUserProfile(userProfile: {
                  firstName: ${formatGraphQLValue(args.firstName)},
                  lastName: ${formatGraphQLValue(args.lastName)},
                  defaultLanguage: ${formatGraphQLValue(
                    args.defaultLanguage.value || args.defaultLanguage
                  )},
                  digestSubscription: ${formatGraphQLValue(
                    isPatient ? false : args.digestSubscription
                  )},
                  is2FAEnabled: ${formatGraphQLValue(args.is2FAEnabled)},
                  color: ${formatGraphQLValue(args.color)},
                  icon: ${formatGraphQLValue(args.icon)},
                  alias: ${formatGraphQLValue(args.alias)}
                  pinCode: ${formatGraphQLValue(args.pinCode)}
                }) {
                  firstName,
                  lastName,
                  defaultLanguage,
                  digestSubscription,
                  is2FAEnabled
                  color
                  icon
                  alias
                  pinCode
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => {
            if (res.status !== 200 && res.status !== 201) {
              setIsProfileSubmitting(false);
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              setIsProfileSubmitting(false);
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              return true;
            } else {
              if (resData.data.updateUserProfile) {
                setIsProfileSubmitting(false);
                toast(`Your profile has been updated`);
                ReactGA.event("user_profile_updated", {
                  user_id: user.id,
                });
                if (args.is2FAEnabled !== null) {
                  const eventName = args.is2FAEnabled
                    ? "user_enabled_2fa"
                    : "user_disabled_2fa";
                  ReactGA.event(eventName, {
                    user_id: user.id,
                  });
                }
              }
              return dispatch(
                updateUserProfileAction(resData.data.updateUserProfile)
              );
            }
          })
          .catch((err) => {
            setIsProfileSubmitting(false);
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    updateUserProfile,
    isProfileSubmitting,
  };
};

export default useUserProfile;
