// import React from "react";
import { useTranslation } from "react-i18next";
import { AssignmentSubmission } from "types";

interface CardButtonsProps {
  filteredSubmissions: Array<AssignmentSubmission>;
  isGroupActiveOrNoGroup: boolean;
  isSimplified: boolean;
  isConfigurable: boolean;
  onRemoveClick?: () => void;
  onFillInClick?: () => void;
  onScheduleClick?: () => void;
  onConfigureClick?: () => void;
  onPreviewClick?: () => void;
}

const useCardButtons = ({
  filteredSubmissions,
  isGroupActiveOrNoGroup,
  isSimplified,
  isConfigurable,
  onRemoveClick,
  onFillInClick,
  onScheduleClick,
  onConfigureClick,
  onPreviewClick,
}: CardButtonsProps) => {
  const { t } = useTranslation(["common"]);

  const buttons = [
    {
      name: "assignment-card_btn_remove",
      value: t("workbook.remove"),
      icon: "Remove",
      style: "assignment-card_btn remove",
      onClick: onRemoveClick,
    },
    {
      name: "assignment-card_btn_fill-in",
      value: t("workbook.fill_in"),
      icon: "FillForm",
      style: "assignment-card_btn",
      onClick: onFillInClick,
    },
    {
      name: "assignment-card_btn_reminders",
      value: t("workbook.reminders"),
      icon: "Bell",
      style: "assignment-card_btn",
      onClick: onScheduleClick,
    },
    {
      name: "assignment-card_btn_configure",
      value: t("workbook.configure"),
      icon: "Configure",
      style: "assignment-card_btn",
      onClick: onConfigureClick,
    },
    {
      name: "assignment-card_btn_preview",
      value: t("workbook.preview"),
      icon: "Preview",
      style: "assignment-card_btn",
      onClick: onPreviewClick,
    },
  ];

  let filteredButtons = buttons;

  if (filteredSubmissions.length !== 0 || !isGroupActiveOrNoGroup) {
    filteredButtons = buttons.filter(
      (button) => button.name !== "assignment-card_btn_remove"
    );
  }

  if (isSimplified || !isGroupActiveOrNoGroup) {
    filteredButtons = buttons.filter(
      (button) =>
        button.name !== "assignment-card_btn_reminders" &&
        button.name !== "assignment-card_btn_configure"
    );
  }

  if (!isConfigurable) {
    filteredButtons = buttons.filter(
      (button) => button.name !== "assignment-card_btn_configure"
    );
  }

  return { filteredButtons };
};

export default useCardButtons;
