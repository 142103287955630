import React from "react";
import { useTranslation } from "react-i18next";
import { Icon, Input } from "components";

interface SubmissionsTableSearchProps {
  data: any;
  onChange: (filteredData: any) => void;
  patientId?: string;
  groupId?: string;
}

const SubmissionsTableSearch = ({
  data,
  onChange,
  patientId,
  groupId,
}: SubmissionsTableSearchProps) => {
  const { t } = useTranslation(["common"]);
  const handleChange = (e: { target: { value: any } }) => {
    searchSubmissions(e.target.value);
  };

  let filterableData = data;

  if (patientId) {
    filterableData = data.filter(
      (submission: { userId: string }) => submission.userId === patientId
    );
  } else if (groupId) {
    filterableData = data.filter(
      (submission: { groupId: string }) => submission.groupId === groupId
    );
  }

  const searchSubmissions = (searchInput: string) => {
    const filteredData = filterableData?.filter(
      (value: { assignmentLabel: string; userNameOrEmail: string }) => {
        return (
          value.assignmentLabel
            ?.toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          value.userNameOrEmail
            ?.toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      }
    );

    return onChange(filteredData);
  };

  return (
    <div className="input-search">
      <Input
        placeholder={t("common_labels.search")}
        inputExtraClass="input-search__input"
        onChange={handleChange}
      />
      <Icon src="Search" alt="magnifying glass" />
    </div>
  );
};

export default SubmissionsTableSearch;
