/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./PatientActionsMenu.styles.scss";
import React, { useState } from "react";
import { Icon, Modal } from "components";
import MoreIcon from "assets/icons/ellipsis-vertical.svg";
import TrashIcon from "assets/icons/trash.svg";
import { useClickOutside } from "utils/hooks";
import PatientDeleteConfirmationModal from "./PatientDeleteConfirmationModal";
import { Patient } from "types";

interface PatientActionsMenuProps {
  patient: Patient | null;
}

const PatientActionsMenu = ({ patient }: PatientActionsMenuProps) => {
  const { node, isNodeVisible, setIsNodeVisible } = useClickOutside();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  return (
    <div className="patient_action-menu-wrapper">
      <div className="patient_action-menu">
        <span
          onClick={(e) => {
            e.stopPropagation();
            setIsNodeVisible(!isNodeVisible);
          }}
        >
          <Icon src={MoreIcon} />
        </span>
      </div>
      <div
        ref={node}
        className={`patient_action-menu-dropdown ${
          isNodeVisible ? "patient_action-menu-dropdown-visible" : ""
        }`}
      >
        <ul className="patient_action-menu-dropdown-menu">
          <li
            className="patient_action-menu-dropdown-menu-item patient_action-menu-dropdown-menu-item-delete-item"
            onClick={(e) => {
              e.stopPropagation();
              setIsDeleteModalOpen(true);
            }}
          >
            <Icon src={TrashIcon} /> Remove
          </li>
        </ul>
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <PatientDeleteConfirmationModal
          patient={patient}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default PatientActionsMenu;
