import { useCurrentUser } from "utils/hooks";
import { useNavigate } from "react-router-dom";
import { useQueryParams } from "utils/hooks";
import { useEffect, useRef } from "react";

const useAuthRedirect = () => {
  const { isAuthenticated, isPatient, isSubscribed, isVerified, user } =
    useCurrentUser();
  const navigate = useNavigate();
  const { hasUrlParams } = useQueryParams();
  const nextUrl = hasUrlParams("next");
  const qrTokenParam = hasUrlParams("qrToken");
  const inviteTherapistToken = hasUrlParams("inviteTherapistToken");
  const fbclid = hasUrlParams("fbclid");

  const onboardingWasSkipped = localStorage.getItem(
    "skipped_therapist_onboarding"
  );
  const prevPathname = useRef(window.location.pathname); // Track the previous pathname to avoid unnecessary navigation.

  useEffect(() => {
    if (!isAuthenticated) return;

    const handleNavigation = () => {
      if (qrTokenParam) {
        window.location.href = `${process.env.REACT_APP_REST_API_URL}/invite-code/scan?qrToken=${qrTokenParam}`;
        return;
      }
      if (user && !isVerified && prevPathname.current !== "/verification") {
        const queryParams = new URLSearchParams();

        // Append necessary parameters to /verification
        if (qrTokenParam) queryParams.append("qrToken", qrTokenParam);
        if (inviteTherapistToken) {
          queryParams.append("inviteTherapistToken", inviteTherapistToken);
        }
        if (fbclid) {
          queryParams.append("fbclid", fbclid);
        }
        if (nextUrl) queryParams.append("next", nextUrl);
        if (fbclid) {
          queryParams.append("fbclid", fbclid);
        }

        const verificationPath = `/verification?${
          queryParams.toString() ? queryParams.toString() : ""
        }`;

        prevPathname.current = "/verification";
        navigate(verificationPath);
        return;
      }

      if (isVerified) {
        if (nextUrl && prevPathname.current !== nextUrl) {
          prevPathname.current = nextUrl;
          navigate(nextUrl);
          return;
        }

        if (isPatient && prevPathname.current !== "/your-assignments") {
          prevPathname.current = "/your-assignments";
          navigate("/your-assignments");
          return;
        }

        if (!isPatient) {
          if (!isSubscribed && prevPathname.current !== "/please-wait") {
            prevPathname.current = "/please-wait";
            navigate("/please-wait");
            return;
          }

          if (onboardingWasSkipped && prevPathname.current !== "/dashboard") {
            prevPathname.current = "/dashboard";
            navigate("/dashboard");
            return;
          }

          if (!onboardingWasSkipped && prevPathname.current !== "/dashboard") {
            prevPathname.current = "/dashboard";
            navigate("/dashboard");
          }
        }
      }
    };

    handleNavigation();
  }, [
    user,
    isAuthenticated,
    isVerified,
    isPatient,
    isSubscribed,
    nextUrl,
    qrTokenParam,
    onboardingWasSkipped,
    navigate,
    inviteTherapistToken,
    fbclid,
  ]);

  return {};
};

export default useAuthRedirect;
