import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AssignmentIcon, WarningIcon } from "components";
import { toAmericanDateFromUnix, toTimeFromUnix } from "utils/helpers";
import { useCurrentUser, useGetPatients } from "utils/hooks";
import { getUserName } from "utils/helpers";

const useSinglePatientSubmissionsDataColumns = () => {
  const { t } = useTranslation(["common"]);
  const { user } = useCurrentUser();
  const { patients } = useGetPatients({});

  const getPatient = useCallback(
    () => (id) => {
      const patient = patients?.find((p) => p.uuid === id);
      return patient ? getUserName(patient) : "";
    },
    [patients]
  );

  const getAuthor = useCallback(
    () => (id) => {
      const patient = patients?.find((p) => p.uuid === id);
      if (patient) {
        return getUserName(patient);
      } else {
        const therapist = user.id === id;
        return therapist ? getUserName(user) : "";
      }
    },
    [patients, user]
  );

  const submissionDataColumns = useMemo(
    () => [
      {
        header: t("patient_assignments.assignment_type_label"),
        accessorKey: "assignmentLabel",
        cell: (props) => {
          return (
            <div className="flex">
              {props.row.original.isNegative || props.row.original.isFlagged ? (
                <WarningIcon variant="small" />
              ) : (
                <AssignmentIcon
                  style={props.row.original.assignmentLabel}
                  alt={props.row.original.assignmentLabel}
                  variant="small"
                  color={props.row.original.color}
                  icon={props.row.original.icon?.src}
                />
              )}
              {props.row.original.assignmentLabel}
            </div>
          );
        },
      },
      {
        header: t("patient_assignments.submission_date_label"),
        cell: (props) => {
          return (
            <>
              <span>
                {toAmericanDateFromUnix(props.row.original.createdAt)}
              </span>
            </>
          );
        },
      },
      {
        header: t("submissions.submission_time_label"),
        cell: (props) => {
          return (
            <>
              <span>{toTimeFromUnix(props.row.original.createdAt)}</span>
            </>
          );
        },
      },
      {
        header: t("patients.patient_label"),
        cell: (props) => {
          return (
            <>
              <span>{getPatient(props.row.original.userId)}</span>
            </>
          );
        },
      },
      {
        header: t("common_labels.therapist_label"),
        cell: () => {
          return (
            <>
              <span>{getUserName(user)}</span>
            </>
          );
        },
      },
      {
        header: t("common_labels.author_label"),
        cell: (props) => {
          return (
            <>
              <span>{getAuthor(props.row.original.authorId)}</span>
            </>
          );
        },
      },
    ],
    [t, getAuthor, getPatient, user]
  );
  return submissionDataColumns;
};

export default useSinglePatientSubmissionsDataColumns;
