import { Button } from "components";
import SafetyPlanIcon from "assets/icons/lifebuoy.svg";
import React from "react";
import { useAddAssignment } from "utils/hooks";

const AddSafetyPlanButton = ({ patient }) => {
  const { addAssignment } = useAddAssignment();
  const handleAddSafetyPlan = () => {
    addAssignment({
      args: {
        actionPlanId: null,
        patientId: patient.uuid,
        type: "SAFETY_PLAN",
        notification: {},
      },
      patientSlug: patient.slug,
      origin: "intervention",
    });
  };
  return (
    <Button
      name="add_safety_plan_button"
      value="Add Safety Plan"
      pill
      icon={SafetyPlanIcon}
      size="small"
      onClick={handleAddSafetyPlan}
    />
  );
};

export default AddSafetyPlanButton;
