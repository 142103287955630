import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./SetPin.styles.scss";
import { Input, Icon, Button, Animate } from "components";

interface SetPinProps {
  onClose: () => void;
  setPinCode: (pin: string) => void;
}
const SetPin = ({ setPinCode, onClose }: SetPinProps) => {
  const { t } = useTranslation(["common"]);
  const [pinOriginal, setPinOriginal] = useState("");
  const [pinCopy, setPinCopy] = useState("");
  const [pinOriginalError, setPinOriginalError] = useState("");
  const [pinCopyError, setPinCopyError] = useState("");
  const [pinMatches, setPinMatches] = useState(false);
  const onlyDigits = /^\d+$/;

  const handleChangeInputOriginal = (e: any) => {
    setPinOriginal(e.target.value);
    if (!onlyDigits.test(e.target.value)) {
      setPinOriginalError(t("profile.create_pin_format_error"));
      return;
    } else {
      setPinOriginalError("");
    }
  };

  const handleChangeInputCopy = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPinCopy(e.target.value);
    if (!onlyDigits.test(e.target.value)) {
      setPinCopyError(t("profile.create_pin_format_error"));
      return;
    } else {
      setPinCopyError("");
    }
    if (pinOriginal.length === 4 && e.target.value.length === 4) {
      if (pinOriginal === e.target.value) {
        setPinMatches(true);
      } else {
        setPinMatches(false);
        setPinCopyError(t("profile.create_pin_match_error"));
      }
    } else {
      setPinMatches(false);
    }
  };

  const handleSubmit = () => {
    if (pinOriginal === pinCopy) {
      setPinCode(pinOriginal);
      onClose();
    }
  };

  return (
    <div className="create-pin">
      <p className="create-pin_description">
        {t("profile.create_pin_description")}
      </p>
      <Animate delay="0.25" animation="fadeInLeft">
        <div className="create-pin_input-cover">
          <Input
            name="pin_original"
            value={pinOriginal}
            label={t("profile.create_pin_enter_label")}
            hint={t("profile.create_pin_enter_hint")}
            placeholder="****"
            type="password"
            maxLength={4}
            pattern="[0-9]{4}"
            inputMode="numeric"
            onInput={handleChangeInputOriginal}
            error={pinOriginalError}
          />
        </div>{" "}
      </Animate>
      <Animate delay="0.45" animation="fadeInLeft">
        <div className="create-pin_input-cover">
          <Input
            name="pin_copy"
            value={pinCopy}
            label={t("profile.create_pin_confirm_label")}
            placeholder="****"
            type="password"
            maxLength={4}
            pattern="[0-9]{4}"
            inputMode="numeric"
            onChange={handleChangeInputCopy}
            error={pinCopyError}
          />
          {pinMatches && (
            <div className="create-pin_check-icon">
              <Icon src={"CircleCheck"} alt="check arrow" />
            </div>
          )}
        </div>
      </Animate>
      <Button
        name="create-pin_submit-btn"
        value={t("profile.create_pin_save_btn")}
        extraClass="submit-button b-fixed"
        pill
        disabled={!pinMatches}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default SetPin;
