import { SubmissionScore } from "types";
import RichTextDisplayField from "../RichTextDisplayField";
import Gauge from "../Gauge/Gauge";
import "./ScoreCard.styles.scss";
import React from "react";
import { roundToNearestWhole } from "utils/helpers";

interface ScoreCardProps {
  score?: SubmissionScore | null;
}

const ScoreCard = ({ score }: ScoreCardProps) => {
  if (!score) {
    return <></>;
  }

  // Find the score display that matches the current score
  const currentScoreDisplay = score.scoreDisplays?.find(
    (display: { min: number; max: number; name: string }) =>
      score.totalScore >= display.min && score.totalScore <= display.max
  );

  // Get the min and max from all score displays
  const min = Math.min(
    ...(score.scoreDisplays?.map((d: { min: number }) => d.min) || [0])
  );
  const max = Math.max(
    ...(score.scoreDisplays?.map((d: { max: number }) => d.max) || [100])
  );

  return (
    <div className="score-card">
      <div className="score-card__score">
        <Gauge
          value={score.totalScore}
          min={min}
          max={max}
          color={score.color}
          label={currentScoreDisplay?.name || "Score"}
          scoringType={score.scoringType}
          displayScoreRange={score.scoreDisplays ? true : false}
          scoreSuffix={score.scoreSuffix}
          scoreAsPercentage={score.scoreAsPercentage}
        />
      </div>
      {score.fieldsetScores && score.fieldsetScores.length > 0 && (
        <>
          <div className="score-card__divider" />
          <div className="score-card__fieldsets">
            <span className="score-card__fieldsetsLabel">Fieldset Scores</span>
            <ul className="score-card__fieldsetsList">
              {score.fieldsetScores.map((fieldset, index) => (
                <li key={index} className="score-card__fieldsetItem">
                  {fieldset.name}: {roundToNearestWhole(fieldset.score)} (
                  {fieldset.scoringType === "Average" ? "avg." : "total"})
                </li>
              ))}
            </ul>
          </div>
          <div className="score-card__divider" />
        </>
      )}
      <div className="score-card__instructions">
        <span className="score-card__instructionsLabel">Instructions</span>
        <div className="score-card__instructionContent">
          <RichTextDisplayField value={score?.scoringInstructions} />
        </div>
      </div>
    </div>
  );
};

export default ScoreCard;
