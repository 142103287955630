import { useEffect, useState, RefObject } from "react";
import { ScoreMappings } from "types";

interface UseRangeFieldProps {
  defaultValue: { value: number; score: number; textValue: string };
  min?: number;
  max?: number;
  step?: number;
  id: string;
  value: { value: number; score: number; textValue: string } | number;
  bubbleRef: RefObject<HTMLDivElement>;
  scoreMappings?: ScoreMappings[];
}

// Helper to normalize any value into a consistent format
const normalizeValue = (
  rawValue: any,
  scoreMappings: ScoreMappings[] = []
): { value: number; score: number; textValue: string } => {
  // Handle number case
  if (typeof rawValue === "number") {
    const matchedMapping = scoreMappings.find(
      (mapping) => mapping.scoreValue === rawValue
    );
    return {
      value: rawValue,
      score: rawValue,
      textValue: matchedMapping?.textValue || rawValue.toString(),
    };
  }

  // Handle string case
  if (typeof rawValue === "string") {
    try {
      const parsed = JSON.parse(rawValue);
      if (typeof parsed === "object") {
        return {
          value: Number(parsed.value) || 0,
          score: Number(parsed.score) || 0,
          textValue: parsed.textValue || parsed.value?.toString() || "0",
        };
      }
      // If parsed value is a number
      const numValue = Number(parsed);
      const matchedMapping = scoreMappings.find(
        (mapping) => mapping.scoreValue === numValue
      );
      return {
        value: numValue,
        score: numValue,
        textValue: matchedMapping?.textValue || numValue.toString(),
      };
    } catch {
      // If string parsing fails, treat as a plain number string
      const numValue = Number(rawValue) || 0;
      const matchedMapping = scoreMappings.find(
        (mapping) => mapping.scoreValue === numValue
      );
      return {
        value: numValue,
        score: numValue,
        textValue: matchedMapping?.textValue || rawValue,
      };
    }
  }

  // Handle object case
  if (typeof rawValue === "object" && rawValue !== null) {
    return {
      value: Number(rawValue.value) || 0,
      score: Number(rawValue.score) || 0,
      textValue: rawValue.textValue || rawValue.value?.toString() || "0",
    };
  }

  // Fallback case
  return { value: 0, score: 0, textValue: "0" };
};

const useRangeField = ({
  value = { value: 5, score: 0, textValue: "5" },
  id,
  min = 0,
  max = 100,
  defaultValue,
  bubbleRef,
  scoreMappings = [],
}: UseRangeFieldProps) => {
  const parsedScoreMappings = Array.isArray(scoreMappings) ? scoreMappings : [];
  const normalizedValue = normalizeValue(value, parsedScoreMappings);
  const normalizedDefault = normalizeValue(defaultValue, parsedScoreMappings);

  const [currentValue, setCurrentValue] = useState(
    normalizedValue.value || normalizedDefault.value
  );
  const [currentScore, setCurrentScore] = useState(
    normalizedValue.score || normalizedDefault.score
  );
  const [textValue, setTextValue] = useState(
    normalizedValue.textValue || normalizedDefault.textValue
  );

  const getFinalValueObject = (newValue: number) => {
    if (!parsedScoreMappings || parsedScoreMappings.length === 0) {
      return {
        value: newValue,
        score: newValue,
        textValue: newValue.toString(),
      };
    }

    const matchedMapping = parsedScoreMappings.find(
      (mapping) => mapping.scoreValue === newValue
    );

    return {
      value: newValue,
      score: matchedMapping ? matchedMapping.scoreValue : newValue,
      textValue: matchedMapping
        ? matchedMapping.textValue
        : newValue.toString(),
    };
  };

  const handleValueChange = (newValue: number) => {
    const { value, score, textValue } = getFinalValueObject(newValue);
    setCurrentValue(value);
    setCurrentScore(score);
    setTextValue(textValue);
  };

  useEffect(() => {
    if (bubbleRef.current) {
      const locationValue = ((currentValue - min) * 100) / (max - min);
      bubbleRef.current.style.left = `calc(${locationValue}% + (${
        8 - locationValue * 0.15
      }px))`;
      bubbleRef.current.innerText = textValue;
    }
  }, [currentValue, textValue, id, max, min, bubbleRef]);

  return {
    currentValue,
    currentScore,
    textValue,
    handleValueChange,
    getFinalValueObject,
  };
};

export default useRangeField;
