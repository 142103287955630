import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import {
  useCurrentUser,
  useLogout,
  useFetchAssignments,
  useFetchMoodScales,
  useFetchPatients,
  useFetchSubmissions,
  useFetchTherapistProfile,
  useFetchTherapists,
  useFetchGroups,
  useFetchPractices,
  useFetchFiles,
} from "utils/hooks";
import { verifyAuthenticatedUser } from "store/actions/auth";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface verifyVeriricationCodeProps {
  code: string;
  email: string;
  inviteTherapistToken: string | null;
  isFromPasswordReset: boolean | null;
}

const useVerifyVerificationCode = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user, isTherapist, isSubscribed, isPatient } = useCurrentUser();
  const history = useNavigate();

  const { fetchAllPatients } = useFetchPatients();
  const { fetchAllTherapists } = useFetchTherapists();
  const { fetchAllSubmissions } = useFetchSubmissions();
  const { fetchTherapistProfile } = useFetchTherapistProfile();
  const { fetchAllAssignments } = useFetchAssignments();
  const { fetchAllMoodScales } = useFetchMoodScales();
  const { fetchGroups } = useFetchGroups();
  const { fetchPractices } = useFetchPractices();
  const { fetchTherapistFiles } = useFetchFiles();

  const verifyVeriricationCode = useCallback(
    async (args: verifyVeriricationCodeProps) => {
      const { code, email, inviteTherapistToken, isFromPasswordReset } = args;
      const timezoneOffset = new Date().getTimezoneOffset();

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                verifyUserCode(
                  email: "${email}",
                  code: "${code}",
                  inviteTherapistToken: "${inviteTherapistToken}",
                  isFromPasswordReset: ${isFromPasswordReset},
                )
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-User-Timezone-Offset": timezoneOffset.toString(),
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "verify_account",
          },
        });

        const resData = await response.json();

        if (resData.data.verifyUserCode) {
          if (isFromPasswordReset) {
            toast("Your email address is now verified.");

            return history("/new-password", { state: { email } });
          }

          ReactGA.event("account_verification_success", {
            user_id: user?.id,
            user_type: user?.type,
            user_verified: user?.verified,
          });

          toast("Your account is now verified.");

          dispatch(verifyAuthenticatedUser());

          if (isTherapist) {
            fetchAllPatients();
            fetchAllSubmissions();
            fetchTherapistProfile();
            fetchAllAssignments();
            fetchAllMoodScales();
            fetchGroups();
            fetchPractices();
            fetchTherapistFiles();
          }

          if (isPatient) {
            fetchAllTherapists();
            fetchAllAssignments();
            fetchGroups();
          }

          let destination = "/submissions";
          if (isTherapist && isSubscribed) {
            destination = "/dashboard";
          }
          if (isTherapist && !isSubscribed) {
            destination = "/please-wait";
          }

          if (!isTherapist) {
            destination = "/your-assignments";
          }
          return history(destination);
        } else {
          if (
            resData.errors &&
            resData.errors[0].message.includes("BRKN_6001")
          ) {
            toast.warn("For security purposes please log in again.");
            logOut();
          } else {
            toast.error("Verification failed.");
          }
        }
      } catch (error) {
        console.error(error);
        toast.error("An error occurred while verifying the code.");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      history,
      isSubscribed,
      isTherapist,
      logOut,
      user?.activeOrganizationId,
      user?.email,
      user?.id,
      user?.type,
      user?.verified,
      isPatient,
    ]
  );

  return {
    verifyVeriricationCode,
  };
};

export default useVerifyVerificationCode;
