import { useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useCurrentUser, useLogout, useFetchGroups } from "utils/hooks";
import { formatInput } from "utils/helpers";
import { addGroupAction } from "store/actions/groups";
import { useNavigate } from "react-router-dom";

const useCreateGroup = () => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchGroups } = useFetchGroups();
  const { user } = useCurrentUser();
  const history = useNavigate();

  const submitCreateGroup = (args: any) => {
    if (!args) {
      return null;
    }

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                createGroup(group: {
                  name: "${formatInput(args.name)}",
                  description: "${formatInput(args.description)}",
                  location: "${formatInput(args.location)}",
                  address: {
                    address1: "${formatInput(args.address1)}",
                    address2: "${formatInput(args.address2)}",
                    city: "${formatInput(args.city)}",
                    state: "${formatInput(args.state?.value)}",
                    country: "${formatInput(args.country?.value)}",
                  }
                }) {
                  uuid
                  name
                  description
                  slug
                  location
                  address {
                    address1
                    address2
                    city
                    state
                    country
                  }
                  status
                  createdAt
                  practiceId
                  therapistHistory {
                    therapistId
                    assignedAt
                    unassignedAt
                  }
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "create_groups",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                setIsSubmitSuccessful(true);
                toast.warn(`For security purposes please log in again.`);
                logOut();
              }
              if (error.errors[0].message.includes("GROUP_LIMIT_REACHED")) {
                toast.warn(`Group limit reached, please upgrade`);
                setIsSubmitSuccessful(true);
                resolve();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              setIsSubmitSuccessful(true);
              resolve();
              return true;
            } else {
              fetchGroups();
              toast("Your group has been created");
              setIsSubmitSuccessful(true);
              resolve();
              ReactGA.event("group_created_success", {
                user_id: user?.id,
                group_id: resData.data.createGroup.uuid,
              });
              dispatch(addGroupAction(resData.data.createGroup));
              fetchGroups();
              return history(
                `/groups/active/${resData.data.createGroup.slug}/workbook`
              );
            }
          })
          .catch((err) => {
            setIsSubmitSuccessful(true);
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitCreateGroup,
    isSubmitSuccessful,
  };
};

export default useCreateGroup;
