import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Animate,
  Button,
  DateField,
  Textarea,
  Select,
  AssignToggle,
} from "components";
import {
  useAddNote,
  useUpdateNote,
  useGetPractices,
  useGetPracticeUsers,
} from "utils/hooks";
import { noteCategories } from "./options";
import { Note } from "types";

interface AddNoteFormProps {
  onSuccess: () => void;
  note: Note | null;
  patientId: string;
}

const AddNoteForm = ({ onSuccess, note, patientId }: AddNoteFormProps) => {
  const { register, handleSubmit, errors, formState, control } = useForm();
  const { submitAddNote, loading, isSubmitSuccessful } = useAddNote({
    patientId,
  });
  const { submitUpdateNote, loadingUpdate, isUpdateSuccessful } = useUpdateNote(
    {
      note,
    }
  );
  const { isSubmitting } = formState;
  const { t } = useTranslation("common");
  const { practices } = useGetPractices({});
  const { practiceUsers } = useGetPracticeUsers({
    practiceId: practices.length !== 0 ? practices[0].uuid : null,
  });

  useEffect(() => {
    if (isSubmitSuccessful || isUpdateSuccessful) {
      onSuccess();
    }
  }, [isSubmitSuccessful, isUpdateSuccessful, onSuccess]);

  const getCategory = (note: Note) => {
    if (!note?.category) return noteCategories[0];
    return noteCategories.find((c) => c.value === note.category);
  };

  return (
    <div id="add_or_edit_note_form">
      <form
        onSubmit={handleSubmit(note ? submitUpdateNote : submitAddNote)}
        autoComplete="off"
      >
        <Animate
          animation="fadeInLeft"
          delay=".25"
          style={{ position: "relative", zIndex: "99999" }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={note ? new Date(note?.date) : new Date()}
            render={({ onChange, value }) => (
              <DateField
                name="date"
                dateFormat="MM/dd/yyyy h:mm"
                label={t("assignment_builder.date_label")}
                placeholder="Enter a date"
                selected={value}
                onChange={onChange}
                showMonthDropdown
                showYearDropdown
                showTimeSelect
              />
            )}
          />
        </Animate>
        <Animate
          animation="fadeInLeft"
          delay=".25"
          style={{ position: "relative" }}
        >
          <Controller
            name="category"
            control={control}
            defaultValue={note ? getCategory(note) : noteCategories[0]}
            render={({ onChange, value }) => (
              <Select
                name="category"
                options={noteCategories}
                value={value}
                defaultValue={noteCategories[0]}
                label={t("notes.category")}
                hint={t("notes.set_category_label")}
                onChange={onChange}
              />
            )}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".25">
          <Textarea
            name="body"
            label={t("notes.note")}
            placeholder={t("notes.write_note_placeholder")}
            forwardRef={register({ required: true })}
            error={errors.description && "Please enter some text for your note"}
            rows="5"
            defaultValue={note?.body}
          />
        </Animate>
        {practiceUsers?.length > 1 && (
          <Animate animation="fadeInLeft" delay=".25">
            <Controller
              name="mailing"
              control={control}
              defaultValue={false}
              render={({ onChange, value }) => (
                <AssignToggle
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                  label={t("notes.email_to_admin")}
                />
              )}
            />
          </Animate>
        )}

        <Button
          type="submit"
          name="add_or_edit_note_button"
          value={t("notes.save_note")}
          extraClass="pill submit-button b-fixed"
          isLoading={isSubmitting || loading || loadingUpdate}
        />
      </form>
    </div>
  );
};

export default AddNoteForm;
