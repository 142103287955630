import "./RemoveResourceConfirmationModal.styles.scss";
import React from "react";
import { Icon, Button } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";
import { useUnassignLibraryResource, useUnassignCollection } from "utils/hooks";

const RemoveResourceConfirmationModal = ({
  resource,
  collection,
  onCancel,
  patient,
}) => {
  const { unassignLibraryResource } = useUnassignLibraryResource({
    resourceId: resource?.uuid,
    patientId: patient.uuid,
  });
  const { unassignCollection } = useUnassignCollection({
    collectionId: collection?.uuid,
    patientId: patient.uuid,
  });

  return (
    <div className="resource-removal-confirmation">
      <div className="resource-removal-confirmation__icon">
        <Icon src={WarningIcon} alt="Warning Icon" />
      </div>
      <div className="resource-removal-confirmation__title">
        Are you sure you want to remove this resource?
      </div>
      <div className="resource-removal-confirmation__body">
        {patient.firstName} will no longer have access.
      </div>
      <div className="resource-removal-confirmation__buttons">
        <Button
          onClick={onCancel}
          name={`cancel_remove_resource_button`}
          value="Never mind"
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => {
            collection ? unassignCollection() : unassignLibraryResource();
            onCancel();
          }}
          name={`confirm_remove_resource_button`}
          value="Yes, remove it"
          extraClass="danger"
          size="small"
        />
      </div>
    </div>
  );
};

export default RemoveResourceConfirmationModal;
