import { useCurrentUser } from "utils/hooks";
import { useNavigate } from "react-router-dom";
import { useQueryParams } from "utils/hooks";
import { useRef } from "react";

const useGuestRedirect = () => {
  const { isAuthenticated, isVerified, pinVerified, isTherapist } =
    useCurrentUser();
  const history = useNavigate();

  const { hasUrlParams } = useQueryParams();
  const nextUrl = hasUrlParams("next");
  const prevPathname = useRef(window.location.pathname);

  const redirect = () => {
    if (!isAuthenticated) {
      return history("/login");
    }
    if (isTherapist && !pinVerified) history("/pin-verification");
    if (
      isAuthenticated &&
      !isVerified &&
      prevPathname.current !== "/verification"
    ) {
      return history("/verification");
    } else {
      if (nextUrl) {
        return history(nextUrl);
      }
    }
  };

  return {
    redirect,
  };
};

export default useGuestRedirect;
