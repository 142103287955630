import React, { useEffect, useRef } from "react";
import { roundToNearestWhole } from "utils/helpers";
import "./Gauge.styles.scss";

interface GaugeProps {
  value: number;
  min: number;
  max: number;
  color: string;
  label: string;
  scoringType: "Total" | "Average";
  size?: number;
  strokeWidth?: number;
  displayScoreRange?: boolean;
  scoreSuffix?: string;
  scoreAsPercentage?: boolean;
}

const Gauge: React.FC<GaugeProps> = ({
  value,
  min,
  max,
  color,
  label,
  scoringType,
  size = 150,
  strokeWidth = 10,
  displayScoreRange,
  scoreSuffix,
  scoreAsPercentage,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const center = size / 2;
  const radius = (size - strokeWidth) / 2;

  // Calculate the total range including negative numbers
  const totalRange = Math.abs(max - min);
  // Calculate percentage based on the total range and clamp between 0 and 100
  const percentage = Math.min(
    Math.max(((value - min) / totalRange) * 100, 0),
    100
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Clear the entire canvas
    ctx.clearRect(0, 0, size, size);

    // Background arc
    ctx.beginPath();
    ctx.arc(center, center, radius, Math.PI, 0);
    ctx.strokeStyle = "#f5f5f5";
    ctx.lineWidth = strokeWidth / 2;
    ctx.lineCap = "round";
    ctx.stroke();

    // Animated value arc
    let progress = 0;
    let animationFrameId: number;

    const animate = () => {
      progress += 2;
      if (progress > percentage) return;

      // Clear the entire canvas before redrawing
      ctx.clearRect(0, 0, size, size);

      // Redraw background arc
      ctx.beginPath();
      ctx.arc(center, center, radius, Math.PI, 0);
      ctx.strokeStyle = "#f5f5f5";
      ctx.lineWidth = strokeWidth / 2;
      ctx.lineCap = "round";
      ctx.stroke();

      // Redraw value arc
      ctx.beginPath();
      ctx.arc(
        center,
        center,
        radius,
        Math.PI,
        Math.PI + (Math.PI * progress) / 100
      );
      ctx.strokeStyle = color;
      ctx.lineWidth = strokeWidth;
      ctx.lineCap = "round";
      ctx.stroke();

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    // Cleanup function to cancel animation frame
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [value, min, max, color, size, strokeWidth, center, radius, percentage]);

  return (
    <div className="gauge">
      <canvas
        ref={canvasRef}
        width={size}
        height={size}
        key={`${value}-${min}-${max}-${color}`} // Force re-render when these values change
      />
      <div className="gauge-content">
        {scoringType && (
          <span className="gauge-scoring-type">{scoringType} Score</span>
        )}
        <span className="gauge-score" style={{ color: color }}>
          {scoreAsPercentage ? value.toFixed(1) : roundToNearestWhole(value)}
          {scoreSuffix}
        </span>
        <span className="gauge-label">{label}</span>
        {displayScoreRange && (
          <div className="gauge-min-max-container">
            <span className="gauge-min-label">{min}</span>
            <span className="gauge-max-label">{max}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Gauge;
