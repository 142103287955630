import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./PatientWorkbook.styles.scss";
import { Assignment } from "types";
import {
  useGetAssignments,
  useGetPatients,
  useQueryParams,
  useGetSubmissions,
  useUpdateAssignment,
  useUpdateNotification,
} from "utils/hooks";
import {
  PageActionBar,
  AssignmentCard,
  AssignmentIcon,
  SidePanel,
  Modal,
  PageLoading,
} from "components";
import AddAssignmentsButton from "pages/AppPages/TherapistPages/SinglePatient/components/PatientWorkbook/components/AddAssignmentsButton";
import { ASSIGNMENT_STATUSES } from "utils/constants";
import useAssignmentDetailsTabs from "./components/AssignmentDetails/useAssignmentDetailsTabs";
import AssignmentSettings from "./components/AssignmentDetails/AssignmentSettings/AssignmentSettings";
import AssignmentFields from "./components/AssignmentDetails/AssignmentFields";
import WorkbookEmptyState from "./components/WorkbookEmptyState";
import ReminderDetails from "./components/ReminderDetails";
import DeleteAssignmentConfirmationModal from "./components/AssignmentDetails/AssignmentSettings/DeleteAssignmentConfirmationModal";
import { toAmericanDate, toUnixTimestamp } from "utils/helpers";
import ConfigureAssignment from "./components/ConfigureAssignment";
import SaveAssignmentConfigureModal from "./components/ConfigureAssignment/components/SaveAssignmentConfigureModal";
import FillInForm from "pages/AppPages/Shared/FillInForm";
import { useAssignmentStore } from "pages/AppPages/PatientPages/AssignmentsForPatients/assignmentStore";

interface PortaledModalProps {
  component: React.ReactNode;
}

const PortaledModal: React.FC<PortaledModalProps> = ({ component }) => {
  const modalRoot = document.getElementById("root");

  return modalRoot ? ReactDOM.createPortal(component, modalRoot) : null;
};

const PatientWorkbook: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { patient } = useGetPatients({ slug: uuid });
  const history = useNavigate();
  const { hasUrlParams } = useQueryParams();
  const isFromNewAssignment = hasUrlParams("assignment-added");
  const assignmentSlug = hasUrlParams("assignment");
  const category = hasUrlParams("category");
  const { t } = useTranslation("common");

  const { assignments } = useGetAssignments({
    patientId: patient?.uuid,
  });
  const { patientSubmissions } = useGetSubmissions({ patientSlug: uuid });
  const { unsavedReminderSettings, updateUnsavedRemindSettings } =
    useUpdateNotification();
  const { unsavedConfigureSettings, updateUnsavedConfigSettings } =
    useUpdateAssignment();
  const { dispatch } = useAssignmentStore();

  const [currentAssignment, setCurrentAssignment] = useState<any>(null);
  const [showAssignmentPanel, setShowAssignmentPanel] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [assignmentToDelete, setAssignmentToDelete] = useState<any>(null);
  const [sidePanelTitle, setSidePanelTitle] = useState<string | undefined>();
  const isInfosheet = category === "INFOSHEET";
  const { assignmentDetailTabs, activeTab, handleTabChange } =
    useAssignmentDetailsTabs({ isInfosheet });
  const [askToSaveSettings, setAskToSaveSettings] = useState<boolean>(false);
  const [canSaveSettings, setCanSaveSettings] = useState<boolean>(false);

  useEffect(() => {
    if (unsavedReminderSettings?.length === 0 && !unsavedConfigureSettings) {
      setCanSaveSettings(false);
      setAskToSaveSettings(false);
    }
  }, [unsavedReminderSettings, unsavedConfigureSettings]);

  const handleAssignmentPanelClose = () => {
    if (unsavedConfigureSettings || unsavedReminderSettings?.length !== 0) {
      setAskToSaveSettings(true);
    } else {
      setIsDeleteModalOpen(false);
      setCurrentAssignment(null);
      setShowAssignmentPanel(false);
      setCanSaveSettings(false);
      history(`/patients/${patient?.slug}/workbook`);
    }
  };

  const visibleAssignments = assignments
    ?.filter(
      ({ status, type }: any) =>
        status === ASSIGNMENT_STATUSES.VISIBLE && type !== "SAFETY_PLAN"
    )
    .slice()
    .sort(
      (a: { createdAt: number }, b: { createdAt: number }) =>
        b.createdAt - a.createdAt
    );

  useEffect(() => {
    if (isFromNewAssignment && assignmentSlug) {
      const assignmentFromSlug = assignments?.find(
        (a: { slug: string }) => a.slug === assignmentSlug
      );
      setCurrentAssignment(assignmentFromSlug || null);
      setShowAssignmentPanel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFromNewAssignment, assignmentSlug]);

  const filteredTabs = React.useMemo(() => {
    if (currentAssignment?.type === "INFOSHEET") {
      return assignmentDetailTabs.filter((tab) => tab.name !== "Configure");
    }
    return assignmentDetailTabs;
  }, [assignmentDetailTabs, currentAssignment]);

  const constructSidePanel = (
    tabNumber: number,
    assignment: Assignment,
    title: string
  ) => {
    handleTabChange(filteredTabs[tabNumber]);
    setCurrentAssignment(assignment);
    setShowAssignmentPanel(true);
    setSidePanelTitle(title);
  };

  const handleCloseConfigurePanelWithoutSaving = () => {
    if (unsavedReminderSettings?.length !== 0) {
      updateUnsavedRemindSettings([]);
    }
    if (unsavedConfigureSettings) {
      updateUnsavedConfigSettings(false);
    }
    setAskToSaveSettings(false);
    setIsDeleteModalOpen(false);
    setShowAssignmentPanel(false);
    history(`/patients/${patient?.slug}/workbook`);
  };

  const createSidePanelTitle = () => {
    if (!currentAssignment) return "";

    return (
      <>
        {activeTab.name === "Fill In" ? (
          <div className="side-panel__header-title_fill">
            {t("workbook.fill_in_title")} &quot;{currentAssignment.label}&quot;
          </div>
        ) : (
          <div className="patient-workbook-panel-tabs">
            <AssignmentIcon
              icon={currentAssignment?.icon?.src}
              style={currentAssignment?.type}
              color={currentAssignment?.color}
            />
            <h4 className="side-panel__header-title">
              {currentAssignment?.label}
            </h4>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {visibleAssignments ? (
        <div className="workbook-page">
          <PageActionBar>
            <div className="workbook-page-header">
              <AddAssignmentsButton patientSlug={patient?.slug} />
            </div>
          </PageActionBar>

          <div className="workbook-page-body">
            <div className="workbook-page-section">
              {visibleAssignments?.length === 0 ? (
                <WorkbookEmptyState patient={patient} />
              ) : (
                <>
                  {visibleAssignments?.map((assignment: Assignment) => {
                    const { notification } = assignment;

                    const notificationsLive = notification?.status === "LIVE";
                    const notificationEndTimestamp =
                      notification?.endDate && notification.endDate !== "null"
                        ? toUnixTimestamp(notification.endDate)
                        : null;

                    const now =
                      toUnixTimestamp(new Date()) || Number.MAX_SAFE_INTEGER;
                    const notificationsExpired =
                      notificationEndTimestamp !== null
                        ? notificationEndTimestamp < now
                        : false;

                    return (
                      <AssignmentCard
                        isExpired={notificationsExpired}
                        isActive={false}
                        showSubtitle={notificationsLive}
                        subtitle={
                          notificationsLive && !notificationsExpired ? (
                            <ReminderDetails notification={notification} />
                          ) : notificationsExpired ? (
                            <span>
                              Completed: {toAmericanDate(notification?.endDate)}
                            </span>
                          ) : null
                        }
                        key={assignment.slug}
                        assignment={assignment}
                        category={assignment.category}
                        submissions={patientSubmissions}
                        onRemoveClick={() => {
                          setAssignmentToDelete(assignment);
                          setIsDeleteModalOpen(true);
                        }}
                        onFillInClick={() => {
                          constructSidePanel(3, assignment, "");
                          dispatch({
                            location: {
                              assignmentSlug: assignment?.slug,
                              sectionSlug: "",
                            },
                          });
                        }}
                        onScheduleClick={() => {
                          constructSidePanel(0, assignment, "");
                        }}
                        onConfigureClick={() => {
                          constructSidePanel(
                            1,
                            assignment,
                            "Configure labels and visibility"
                          );
                        }}
                        onPreviewClick={() => {
                          constructSidePanel(2, assignment, "Preview");
                        }}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <PageLoading vh50 />
      )}

      <SidePanel
        title={createSidePanelTitle()}
        isVisible={showAssignmentPanel}
        onClose={handleAssignmentPanelClose}
      >
        {sidePanelTitle && activeTab.name !== "Fill In" && (
          <h1 className="side-panel_title">{sidePanelTitle}</h1>
        )}
        <>
          {activeTab.name === "Schedule" && (
            <AssignmentSettings
              assignment={currentAssignment}
              onClose={handleAssignmentPanelClose}
              canSaveSettings={canSaveSettings}
              setCanSaveSettings={setCanSaveSettings}
            />
          )}
          {activeTab.name === "Configure" && (
            <ConfigureAssignment
              assignment={currentAssignment}
              canSaveSettings={canSaveSettings}
              setCanSaveSettings={setCanSaveSettings}
            />
          )}
          {activeTab.name === "Preview" && (
            <AssignmentFields
              assignment={currentAssignment}
              panel={"Preview"}
            />
          )}
          {activeTab.name === "Fill In" && (
            <FillInForm
              assignment={currentAssignment}
              onClose={handleAssignmentPanelClose}
            />
          )}
        </>
      </SidePanel>

      <PortaledModal
        component={
          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
          >
            <DeleteAssignmentConfirmationModal
              assignment={assignmentToDelete}
              isForPatient={true}
              onCancel={() => setIsDeleteModalOpen(false)}
            />
          </Modal>
        }
      />

      {/* This modal asks therapist to save updated settings in Configure/Reminders or not before tab closing*/}
      <PortaledModal
        component={
          <Modal
            isOpen={askToSaveSettings}
            onClose={() => setAskToSaveSettings(false)}
          >
            <SaveAssignmentConfigureModal
              onCancel={() => {
                handleCloseConfigurePanelWithoutSaving();
              }}
              onConfirm={() => {
                setCanSaveSettings(true);
                setAskToSaveSettings(false);
              }}
            />
          </Modal>
        }
      />
    </>
  );
};

export default PatientWorkbook;
