import React from "react";
import { User } from "types";
import { Avatar, Icon, IconButton } from "components";
import "./SingleMember.styles.scss";
import { toAmericanDate, ageFromDOB } from "utils/helpers";
import { useGetPatients } from "utils/hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface SingleMemberProps {
  member: User | null;
}

const SingleMember = ({ member }: SingleMemberProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const { firstName, lastName, role, email, profile, slug } = member ?? {};
  const { patient } = useGetPatients({ slug });

  const isPatient = role === "member";
  const isFullNameNullable = firstName === null && lastName === null;

  return (
    <div className="group-member-panel">
      <div className="group-member-panel__info">
        <div className="group-member-panel__info-item">
          <div className="group-member-panel__avatar-icon">
            {isPatient ? (
              <Avatar user={member} />
            ) : (
              <Avatar
                user={member}
                showInitials={true}
                firstName={firstName}
                lastName={lastName}
              />
            )}
          </div>
          <div>
            {isFullNameNullable
              ? t("common_labels.name_not_set")
              : `${firstName} ${lastName}`}
          </div>
        </div>
        <div className="group-member-panel__info-item">
          <span className="group-member-panel__icon">
            <Icon src="IdCard" />
          </span>{" "}
          {role}
        </div>
        <div className="group-member-panel__info-item">
          <span className="group-member-panel__icon">
            <Icon src="Envelope" />
          </span>{" "}
          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
            {email}
          </a>
        </div>
        {isPatient && (
          <>
            <div className="group-member-panel__info-item">
              <span className="group-member-panel__icon">
                <Icon src="IdCard2" />
              </span>
              {profile?.idNumber ? (
                profile?.idNumber
              ) : (
                <i>{t("common_labels.not_set")}</i>
              )}
            </div>
            <div className="group-member-panel__info-item">
              <span className="group-member-panel__icon">
                <Icon src="BirthDayCake2" />
              </span>
              {profile?.dob &&
              profile?.dob !== "undefined" &&
              profile?.dob !== "null" ? (
                <>
                  {toAmericanDate(profile?.dob)}
                  <span className="light-text" style={{ marginLeft: "4px" }}>
                    ({ageFromDOB(profile?.dob)})
                  </span>
                </>
              ) : (
                <i>{t("common_labels.not_set")}</i>
              )}
            </div>
            <div className="group-member-panel__info-item">
              <span className="group-member-panel__icon">
                <Icon src="GenderIcon" />
              </span>
              {profile?.gender ? (
                profile?.gender
              ) : (
                <i>{t("common_labels.not_set")}</i>
              )}
            </div>
          </>
        )}
      </div>
      {isPatient &&
        (patient ? (
          <IconButton
            icon="ExternalLink"
            value="Go to Patient"
            onClick={() => navigate(`/patients/${slug}`)}
            extraClass="blue"
          />
        ) : (
          <p className="group-member-panel__message">
            {t("groups.member_belongs_to_another_therapist")}
          </p>
        ))}
    </div>
  );
};

export default SingleMember;
