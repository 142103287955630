import { format, isValid } from "date-fns";
import { InviteQRCode, Group, User } from "types";

export const snakeToCamel = (str: string): string => {
  return str.toLowerCase().replace(/(_\w)/g, function (m) {
    return m[1].toUpperCase();
  });
};

export const toUnixTimestamp = (
  timestring: string | number | undefined | null | Date
): number | null => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined"
  ) {
    return null;
  }

  const date = new Date(timestring);

  if (isNaN(date.getTime())) {
    // Invalid date
    return null;
  }

  return date.getTime(); // Return timestamp in milliseconds
};

export const toAmericanDate = (
  rawTimestring: string | number | undefined | null | Date
): string | null => {
  const timestring =
    toUnixTimestamp(Number(rawTimestring)) || toUnixTimestamp(rawTimestring);
  if (!timestring || isNaN(timestring)) {
    return null;
  }
  return format(new Date(timestring), "MM/dd/yyyy");
};

export const toAmericanDateWithTime = (
  timestring: string | number | undefined | null
): string | null => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined"
  ) {
    return null;
  }
  return format(new Date(timestring), "MM/dd/yyyy h:mm a");
};

export const toAmericanDateFromUnix = (
  timestring: string | number | undefined | null
): string | null => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined"
  ) {
    return null;
  }
  return format(Number(timestring), "MM/dd/yyyy");
};

export const toAmericanDateWithTimeFromUnix = (
  timestring: string | number | undefined | null
): string | null => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined" ||
    isNaN(Number(timestring))
  ) {
    return null;
  }

  const date = new Date(Number(timestring));

  if (!isValid(date)) {
    return null;
  }

  return format(date, "MM/dd/yyyy h:mm a");
};

export const toTimeFromUnix = (
  timestring: string | number | undefined | null
): string | null => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined"
  ) {
    return timestring as string; // Return the original string
  }

  let timestamp: number;

  if (typeof timestring === "string" && isNaN(Number(timestring))) {
    // If timestring is an ISO 8601 string, convert it to a timestamp
    timestamp = Date.parse(timestring);
  } else {
    // Otherwise, assume it's a number or numeric string and convert
    timestamp = Number(timestring);
  }

  // Check if the timestamp is a valid number
  if (isNaN(timestamp)) {
    console.error("Invalid timestamp: cannot convert to number", timestring);
    return timestring as string; // Return the original string
  }

  const date = new Date(timestamp);

  // Check if the date is valid
  if (!isValid(date)) {
    console.error("Invalid date: created from timestamp", timestamp);
    return timestring as string; // Return the original string
  }

  // Format the valid date
  return format(date, "h:mm a");
};

export const toFullDateWithTimeFromUnix = (
  timestring: string | number | undefined | null
): string | null => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined"
  ) {
    return null;
  }

  const timestamp = Number(timestring);
  const date = new Date(timestamp);

  if (!isValid(date)) {
    return null; // Return null if the date is not valid
  }

  return format(date, "E, LLLL d y h:mm a");
};

export const toReadableTime = (
  timestring: string | number | undefined | null
): string | null => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined"
  ) {
    return null;
  }
  return format(new Date(timestring), "h:mm a");
};

export const ageFromDOB = (
  timestring: string | undefined | null
): number | null => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined"
  ) {
    return null;
  }
  const today = new Date();
  const birthDate = new Date(timestring);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const obfuscateEmail = (
  emailAddress: string,
  starCount?: number
): string => {
  if (!emailAddress) {
    return "";
  }
  const split = emailAddress?.split("@");
  const charCount = starCount || split[0].length - 3;
  const stars = new Array(charCount > 0 ? charCount : 0).fill("*").join("");
  return `${emailAddress.substring(0, 3)}${stars}@${split[1]}`;
};

export const wrapIntoQuotes = (
  value: string | null
): string | null | undefined => {
  if (value !== undefined && value !== null) {
    return `"${value}"`;
  }
  return value;
};

export const flat = <T>(array: T[][]): T[] => {
  return array.flat();
};

export const formatPhoneNumber = (phoneNumberString: string): string => {
  let phone = phoneNumberString.replace(/\D/g, "");
  const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    phone = `${match[1]}${match[2] ? "-" : ""}${match[2]}${
      match[3] ? "-" : ""
    }${match[3]}`;
  }
  return phone;
};

export const toBoolean = (backendBooleanValue: string | boolean): boolean => {
  if (typeof backendBooleanValue === "string") {
    return backendBooleanValue === "true" || backendBooleanValue === "1";
  }
  return backendBooleanValue;
};

export const getInitials = (string: string): string => {
  const names = string.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const escapeQuotes = (
  string: string | undefined | null | number
): string | null => {
  if (!string || string === "null" || string === "undefined") {
    return null;
  }
  return string.toString().replace(/"/g, '\\"');
};

export const formatJson = (options: any): string => {
  if (!options) return "";
  return formatInput(JSON.stringify(options));
};

export const formatInput = (
  string: string | undefined | null | number
): string => {
  const escapedQuotes = escapeQuotes(string);
  if (!escapedQuotes) {
    return "";
  }
  return escapedQuotes.toString().replaceAll("\n", "\\n");
};

export const formatInputForGraphQL = (
  string: string | undefined | null | number
): string => {
  if (string === undefined || string === null) {
    return "";
  }

  const str = string.toString();

  return str
    .replace(/\\/g, "\\\\") // Escape backslashes
    .replace(/"/g, '\\"') // Escape double quotes
    .replace(/\n/g, "\\n") // Escape newlines
    .replace(/\r/g, "\\r") // Escape carriage returns
    .replace(/!/g, "\\!") // Escape exclamation marks
    .replace(/`/g, "\\`") // Escape backticks
    .replace(/\$/g, "\\$") // Escape dollar signs (GraphQL variables)
    .replace(/%/g, "\\%"); // Escape percentage signs (Fix for your error)
};

export const formatJsonForGraphQL = (obj: any): string => {
  if (!obj) return "[]"; // Return an empty object instead of an empty string
  return JSON.stringify(obj).replace(/"([^"]+)":/g, "$1:");
};

export const daysOfWeekFormatter = (
  daysOfWeek: number[] | string[]
): number[] | null => {
  if (!daysOfWeek || daysOfWeek.length === 0) {
    return null;
  }

  if (daysOfWeek.length > 1) {
    return daysOfWeek.map((item) => {
      if (typeof item === "string") {
        return Number(item);
      } else {
        return item;
      }
    });
  }

  if (typeof daysOfWeek[0] === "number") {
    return daysOfWeek as number[];
  }

  return daysOfWeek[0]?.split(",").map((str) => Number(str)) || null;
};

export const getDayDisplay = (
  day: number
): { initials: string; full: string } => {
  let display;
  switch (day) {
    case 0:
      display = {
        initials: "Su",
        full: "Sunday",
      };
      break;
    case 1:
      display = {
        initials: "M",
        full: "Monday",
      };
      break;
    case 2:
      display = {
        initials: "Tu",
        full: "Tuesday",
      };
      break;
    case 3:
      display = {
        initials: "W",
        full: "Wednesday",
      };
      break;
    case 4:
      display = {
        initials: "Th",
        full: "Thursday",
      };
      break;
    case 5:
      display = {
        initials: "F",
        full: "Friday",
      };
      break;
    case 6:
      display = {
        initials: "Sa",
        full: "Saturday",
      };
      break;
    default:
      display = {
        initials: "",
        full: "",
      };
  }

  return display;
};

export const getDaysAsInitials = (
  daysOfWeek: string[] | number[]
): string[] => {
  const activeDays = daysOfWeekFormatter(daysOfWeek);

  return (
    activeDays?.map((day, i) => {
      return ` ${getDayDisplay(day).initials}${
        i < activeDays.length - 1 ? "," : ""
      }`;
    }) || []
  );
};

export const isValidDate = (date: any): Date | null => {
  if (
    date === "null" ||
    date === "undefined" ||
    date === "Invalid Date" ||
    isNaN(date) ||
    !(date instanceof Date)
  ) {
    return null;
  }

  return date;
};

export const slugGenerator = async ({
  string,
}: {
  string: string;
}): Promise<string> => {
  const randomString = Math.random().toString(36)?.substring(7);
  const generatedSlug = `${string}-${randomString}`;
  return generatedSlug;
};

export const smallIdGenerator = async (): Promise<string> => {
  const randomString = Math.random().toString(36)?.substring(7);
  return randomString;
};

export const useAn = (title: string): boolean => {
  const vowels = "aeiouAEIOU";
  return vowels.includes(title[0]);
};

export const formatTitles = (titles: string[]): string => {
  if (!titles || titles.length === 0) return "";

  return titles
    .map((title) => {
      const article = useAn(title) ? "an" : "a";
      return `${article} ${title}`;
    })
    .reduce((prev, curr, index, array) => {
      if (index === 0) {
        return curr; // First element
      } else if (index === array.length - 1) {
        return `${prev}, and ${curr}`; // Last element
      } else {
        return `${prev}, ${curr}`; // Middle elements
      }
    }, "");
};

export const getDefaultNotificationTime = (): Date => {
  const now = new Date();

  now.setHours(20, 0, 0, 0);

  return now;
};

export const truncateText = (text: string, maxLength = 20) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export const capitalizeFirstLetter = (string?: string) => {
  if (!string) {
    return undefined;
  }
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const formatGraphQLValue = (value: any) => {
  if (value === undefined || value === null) return null;
  if (typeof value === "boolean" || typeof value === "number") return value;
  return `"${value}"`;
};

export const pluralize = (count: number, single: string, plural?: string) => {
  if (count !== 1 && plural) {
    return plural;
  }
  return `${single}${count === 1 ? "" : "s"}`;
};

export const findInviteCode = (inviteCodes: InviteQRCode[], groupId?: string) =>
  inviteCodes.find((inviteCode) =>
    groupId ? inviteCode.groupId === groupId : !inviteCode.groupId
  ) || null;

export const isGroupActive = (group: Group) => group.status === "ACTIVE";

export const roundToNearestWhole = (number: number) => {
  if (typeof number !== "number" || isNaN(number)) {
    throw new Error("Invalid input: Please provide a valid number.");
  }
  return Math.round(number);
};

export const getUserName = (user: User | null) => {
  if (!user) return "";
  const name = user.firstName ? user.firstName : "";
  const lastName = user.lastName ? user.lastName : "";
  return user.fullName ? user.fullName : name + " " + lastName;
};
