import "./PatientsDropdown.styles.scss";
import React from "react";
import ReactSelect from "react-select";
import { useGetPatients } from "utils/hooks";
import {
  PatientOption,
  allPatientsOption,
  ValueContainer,
  MultiValue,
} from "./components/PatientOptions";
import { useTranslation } from "react-i18next";
import { Patient } from "types";

interface PatientsDropdownProps {
  hint?: string;
  menuPlacement?: string;
  components?: any;
  isDisabled?: boolean;
  register?: any;
  name?: string;
  label?: string | null;
  placeholder?: string;
  defaultPatient?: {
    uuid: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
  } | null;
  includeEmail?: boolean;
  rules?: any;
  onChange: (selected: any) => void;
  isMulti?: boolean;
  allowSelectAll?: boolean;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  value?: any;
  isClearable?: boolean;
  styles?: any;
  className?: string;
  defaultToText?: boolean;
  patientsList?: Array<Patient>;
}

const PatientsDropdown = ({
  components,
  isDisabled,
  name,
  label = null,
  defaultPatient,
  includeEmail = true,
  onChange,
  isMulti = false,
  allowSelectAll = false,
  closeMenuOnSelect = true,
  hideSelectedOptions = true,
  value,
  isClearable = true,
  styles = {},
  className,
  defaultToText = false,
  placeholder,
  menuPlacement = "bottom",
  patientsList,
}: PatientsDropdownProps) => {
  const { t } = useTranslation(["common"]);
  const { patients } = useGetPatients({});
  const currentPlaceholder = placeholder || t("confirmed_actions.sending");
  const patientsArray = patientsList ? patientsList : patients;
  const mappedPatients = patientsArray.map(
    (patient: { uuid: any; firstName: any; lastName: any; email: any }) => {
      return {
        value: patient.uuid,
        label:
          patient.firstName === null && patient.lastName === null
            ? patient.email
            : `${patient.firstName} ${patient.lastName}${
                includeEmail ? ` - ${patient.email}` : ""
              }`,
      };
    }
  );

  const isFullNameNullable =
    defaultPatient?.firstName === null && defaultPatient?.lastName === null;

  const mappedCurrentPatient = {
    value: defaultPatient && defaultPatient.uuid,
    label: isFullNameNullable
      ? defaultPatient?.email
      : `${defaultPatient?.firstName} ${defaultPatient?.lastName}`,
  };

  const options = allowSelectAll
    ? [allPatientsOption, ...mappedPatients]
    : mappedPatients;

  if (defaultToText && patients.length === 1) {
    return (
      <div className="default-to-text">{`${defaultPatient?.firstName} ${defaultPatient?.lastName}`}</div>
    );
  }

  return (
    <div className={`select ${className}`}>
      {label && <label className="select__label">{label}</label>}
      <ReactSelect
        value={value || defaultPatient}
        isClearable={isClearable}
        className={`select ${className}`}
        styles={styles}
        name={name}
        placeholder={currentPlaceholder}
        options={options}
        components={{
          Option: PatientOption,
          ValueContainer,
          MultiValue,
          ...components,
        }}
        defaultValue={
          defaultPatient || mappedCurrentPatient.value
            ? mappedCurrentPatient
            : null
        }
        isDisabled={isDisabled}
        noOptionsMessage={() => t("therapist_patient_info.no_patients")}
        onChange={(
          selected: { value: string }[] | null,
          event: { action: string }
        ) => {
          if (selected !== null && selected.length > 0) {
            if (
              selected[selected.length - 1].value === allPatientsOption.value
            ) {
              return onChange(options);
            }
            let result: { value: any; label: string }[] | { value: string }[] =
              [];
            if (selected.length === options.length - 1) {
              if (selected.includes(allPatientsOption)) {
                result = selected.filter(
                  (option: { value: string }) =>
                    option.value !== allPatientsOption.value
                );
              } else if (event.action === "select-option") {
                result = options;
              }
              return onChange(result);
            }
          }

          return onChange(selected);
        }}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        menuPlacement={menuPlacement}
      />
    </div>
  );
};

export default PatientsDropdown;
