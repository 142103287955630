import { useDispatch } from "react-redux";
import { fetchGroupsAction } from "store/actions/groups";
import { toast } from "react-toastify";
import { useCurrentUser, useLogout } from "utils/hooks";

const useFetchGroups = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const fetchGroups = () => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getGroups {
                uuid
                name
                description
                slug
                createdAt
                updatedAt
                location
                address {
                  address1
                  address2
                  city
                  state
                  country
                }
                numberOfUsers
                status
                groupOwnerId
                groupMembersIds
                practiceId
                therapistHistory {
                  therapistId
                  assignedAt
                  unassignedAt
                }
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          organizationId: user?.activeOrganizationId || "NA",
          permissionKey: "read_group_information",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong (Code: 02IMN). ${resData.errors[0].message}`
            );
          } else {
            return dispatch(fetchGroupsAction(resData.data.getGroups));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return {
    fetchGroups,
  };
};

export default useFetchGroups;
