import React from "react";
import { useCurrentUser } from "utils/hooks";
import { roundToNearestWhole } from "utils/helpers";

interface RangeSliderResponseProps {
  value: string | number | null | undefined;
  condensed?: boolean;
  showScore?: boolean;
  scoreSuffix?: string;
}

const RangeSliderResponse: React.FC<RangeSliderResponseProps> = ({
  value,
  condensed,
  showScore,
  scoreSuffix,
}) => {
  const { isTherapist } = useCurrentUser();

  const formatValue = (val: any) => {
    if (typeof val === "number") {
      return roundToNearestWhole(val);
    }
    if (typeof val === "string" && !isNaN(Number(val))) {
      return roundToNearestWhole(Number(val));
    }
    return val;
  };

  if (typeof value === "string" && value.trim() !== "") {
    try {
      const parsedValue = JSON.parse(value);
      if (typeof parsedValue === "object" && parsedValue.textValue) {
        return (
          <div className={condensed ? "" : "mb-3"}>
            <div className="flex align-center">
              <small style={{ display: "inline-block", width: "75px" }}>
                <i>Response:</i>
              </small>
              {formatValue(parsedValue.textValue)}
            </div>
            {isTherapist && showScore && (
              <div className="flex align-center">
                <small style={{ display: "inline-block", width: "75px" }}>
                  <i>Score:</i>
                </small>
                {formatValue(parsedValue.score)}
                {scoreSuffix}
              </div>
            )}
          </div>
        );
      } else {
        console.warn(
          "SCALE value is not in the expected object format, falling back to value:",
          value
        );
        return (
          <div className="flex align-center mb-3">
            <small style={{ display: "inline-block", width: "75px" }}>
              <i>Response:</i>
            </small>
            {formatValue(parsedValue.value || value)}
            {scoreSuffix}
          </div>
        );
      }
    } catch (error) {
      console.error("Error parsing SCALE value:", error);
      return (
        <div className="flex align-center">
          {formatValue(value)}
          {scoreSuffix}
        </div>
      );
    }
  } else if (typeof value === "number") {
    return <div className="flex align-center">{formatValue(value)}</div>;
  } else {
    console.warn("Unexpected SCALE value format:", value);
    return <div className="flex align-center">{value || "N/A"}</div>;
  }
};

export default RangeSliderResponse;
