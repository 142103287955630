import { Button } from "components";
import React from "react";
import { useResendPatientInvite } from "utils/hooks";
import MailIcon from "assets/icons/envelope.svg";

const PatientStatus = ({ status, email }) => {
  const { isSubmitting, submitResendInvite } = useResendPatientInvite();
  return (
    <>
      {status === "pending" ? (
        <Button
          onClick={() => {
            submitResendInvite(email);
          }}
          name={`resend_patient_invite`}
          value="Resend Invite"
          extraClass="secondary"
          size="small"
          isLoading={isSubmitting}
          icon={MailIcon}
        />
      ) : null}
    </>
  );
};

export default PatientStatus;
