import React, { useState, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import "./FillInForm.styles.scss";
import { Assignment, User } from "types";
import {
  useGetPatients,
  useGetGroups,
  useGetGroupUsers,
  useFetchSubmissions,
  useCurrentUser,
} from "utils/hooks";
import {
  PatientsDropdown,
  AssignToggle,
  Button,
  BottomModal,
} from "components";
import FillAssignment from "./FillAssignment";
import {
  updatePinVerificationFalse,
  updatePinVerificationTrue,
} from "store/actions/auth";

interface FillInFormProps {
  assignment: Assignment;
  onClose: () => void;
}

const FillInForm = ({ assignment, onClose }: FillInFormProps) => {
  const { t } = useTranslation("common");
  const { uuid } = useParams<{ uuid: string }>();
  const { patient } = useGetPatients({ slug: uuid });
  const { specificGroup: group } = useGetGroups({ slug: uuid });
  const { groupUsers } = useGetGroupUsers({ groupId: group?.uuid });
  const { user } = useCurrentUser();
  const { fetchAllSubmissions } = useFetchSubmissions();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [visibleToPatient, setVisibleToPatient] = useState(false);
  const [requestPin, setRequestPin] = useState(user?.pinCode ? true : false);
  const [selectedPatient, setSelectedPatient] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [showFillInModal, setShowFillInModal] = useState(false);
  const groupPatients = useMemo(
    () => groupUsers?.filter((user: User) => user.role === "member"),
    [groupUsers]
  );

  const handleSubmitFillingIn = () => {
    if (!patient && group && !selectedPatient) {
      toast.warn(t("patients.choose_patient_warn"));
      return;
    }
    setShowFillInModal(true);
  };

  const onCloseFillInModal = () => {
    setSelectedPatient(null);
    setShowFillInModal(false);
    onClose();
    fetchAllSubmissions();
    dispatch(
      requestPin ? updatePinVerificationFalse() : updatePinVerificationTrue()
    );
  };

  return (
    <>
      <p className={`fill_title ${patient ? "mb" : ""}`}>
        <Trans
          i18nKey="common:workbook.fill_in_description"
          components={{ br: <br /> }}
        />
      </p>
      {group && (
        <>
          <div className="fill_label mb">
            {t("workbook.choose_patient_label")}
          </div>
          <PatientsDropdown
            patientsList={groupPatients}
            placeholder={t("workbook.choose_placeholder")}
            onChange={(selected) => setSelectedPatient(selected)}
          />
        </>
      )}
      <div className="fill_toggle-container">
        <AssignToggle
          label={t("workbook.fill_in_submission_visible")}
          labelStyle={"fill_label"}
          checked={visibleToPatient}
          onChange={(e) => setVisibleToPatient(e.target.checked)}
        />
        <div className="fill_hint">
          <Trans
            i18nKey="common:workbook.fill_in_submiss_visible_hint"
            components={{ br: <br /> }}
          />
        </div>
      </div>
      <div
        className={`fill_toggle-container ${user?.pinCode ? "" : "disabled"}`}
      >
        <AssignToggle
          label={t("workbook.fill_pin_label")}
          labelStyle={"fill_label"}
          checked={requestPin}
          onChange={(e) => setRequestPin(e.target.checked)}
        />
        <div className="fill_hint">
          <Trans
            i18nKey="common:workbook.fill_in_pin_hint"
            components={{ br: <br /> }}
          />
        </div>
      </div>
      {!user?.pinCode && (
        <p className="fill_settings_hint">
          <Trans
            i18nKey={"common:profile.set_pin_hint"}
            components={{
              Button: (
                <Button
                  value={t("profile.user_settings")}
                  name="redirect-btn"
                  onClick={() => history("/settings/account")}
                />
              ),
            }}
          />
        </p>
      )}
      <Button
        name="submit_fill_in_assignment"
        value={t("workbook.fill_in_submit_btn")}
        pill={true}
        extraClass="submit-button b-fixed"
        onClick={() => handleSubmitFillingIn()}
      />

      <BottomModal
        isVisible={showFillInModal}
        title={assignment?.label}
        handleClose={onCloseFillInModal}
      >
        <FillAssignment
          singlePatient={patient}
          selectedPatient={selectedPatient}
          group={group}
          assignment={assignment}
          hideFromPatient={!visibleToPatient}
          requestPin={requestPin}
          onClose={onCloseFillInModal}
        />
      </BottomModal>
    </>
  );
};

export default FillInForm;
