import "./CrisisIntervention.styles.scss";
import React, { useState } from "react";
import AddSafetyPlanButton from "./components/AddSafetyPlanButton";
import { useParams } from "react-router-dom";
import { useGetPatients, useSafetyPlan } from "utils/hooks";
import { AlertBar, AssignmentCard, SidePanel } from "components";
import SafetyPlanAddedContent from "./components/SafetyPlanAddedInfo/SafetyPlanAddedContent";
import AssignmentFields from "../PatientWorkbook/components/AssignmentDetails/AssignmentFields";
import { ASSIGNMENT_STATUSES } from "utils/constants";
import { convertSubmissionToData } from "pages/AppPages/PatientPages/SafetyPlanForPatients/utils";
import GeneratedFieldResponseForSafetyPlan from "pages/AppPages/PatientPages/SafetyPlanForPatients/components/GeneratedFieldResponseForSafetyPlan";

const CrisisIntervention = () => {
  const { uuid } = useParams();
  const { patient } = useGetPatients({ slug: uuid });
  const { safetyPlanFromAssignments, safetyPlanSubmission } = useSafetyPlan({
    patientId: patient.uuid,
  });

  const patientSafetyPlan = safetyPlanFromAssignments?.filter(
    (sp) =>
      sp.patientId === patient.uuid && sp.status === ASSIGNMENT_STATUSES.VISIBLE
  );

  const hasPatientSafetyPlan = patientSafetyPlan.length > 0 || null;

  const [showAssignmentPanel, setShowAssignmentPanel] = useState(false);

  let data = [];
  const safetyPlan =
    safetyPlanFromAssignments[safetyPlanFromAssignments?.length - 1];
  if (safetyPlan && safetyPlanSubmission) {
    data = convertSubmissionToData(safetyPlanSubmission, safetyPlan);
  }

  return (
    <div>
      {!hasPatientSafetyPlan && (
        <div>
          <AddSafetyPlanButton patient={patient} />
        </div>
      )}
      {hasPatientSafetyPlan && (
        <>
          <div>
            <AlertBar
              content={<SafetyPlanAddedContent patient={patient} />}
              type="info"
              rounded
              padded
            />
          </div>
          <div>
            <AssignmentCard
              isActive={false}
              showSubtitle={false}
              assignment={patientSafetyPlan[0]}
              onCardClick={() => setShowAssignmentPanel(true)}
            />
          </div>
          <SidePanel
            title={
              <div className="patient-workbook-panel-tabs">
                <h4 className="side-panel__header-title">
                  Safety Plan Preview
                </h4>
              </div>
            }
            isVisible={showAssignmentPanel}
            onClose={() => setShowAssignmentPanel(false)}
          >
            <>
              {data.length === 0 ? (
                <AssignmentFields assignment={patientSafetyPlan[0]} />
              ) : (
                <div>
                  {data.map((item) => (
                    <div key={item.field.uuid}>
                      {item.field.order === 1 && (
                        <strong>
                          Step {item.field.sectionOrder}.{" "}
                          {item.field.sectionLabel}
                        </strong>
                      )}
                      <GeneratedFieldResponseForSafetyPlan
                        response={item.response}
                        field={item.field}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          </SidePanel>
        </>
      )}
    </div>
  );
};

export default CrisisIntervention;
