import { GeneratedFieldResponse } from "../../../../../../components/utility/GeneratedField";
import React from "react";
import { ResponseField, Field } from "types";

interface GeneratedFieldResponseForSafetyPlanProps {
  field: Field;
  response: ResponseField;
}

const GeneratedFieldResponseForSafetyPlan = ({
  field,
  response,
}: GeneratedFieldResponseForSafetyPlanProps) => {
  switch (field.type) {
    case "MULTI_VALUE": {
      const values = JSON.parse(response?.value || "{}");
      return (
        <p className="multi-value-field-safety-plan">
          {[
            `${field.order}.`,
            values.name,
            values["phone-number"] && (
              <a href={`tel:${values["phone-number"]?.replace(/[() -]/g, "")}`}>
                {values["phone-number"]}
              </a>
            ),
            values.location,
          ]
            .filter((element) => element)
            .map((element, index, array) => (
              <span key={index}>
                {element}
                {index < array.length ? " " : ""}
              </span>
            ))}
        </p>
      );
    }
    case "INPUT_LIST": {
      const values = JSON.parse(response?.value || "[]");
      return (
        <ol>
          {values
            .filter((item: string) => item)
            .map((item: string, index: number) => (
              <li key={index + 1}>{item}</li>
            ))}
        </ol>
      );
    }
    default:
      return <GeneratedFieldResponse field={response} />;
  }
};

export default GeneratedFieldResponseForSafetyPlan;
