import "./PatientSubmissions.styles.scss";
import React, { useEffect, useMemo, useState } from "react";
import { PageActionBar, PageLoading } from "components";
import { useGetSubmissions } from "utils/hooks";
import { useParams } from "react-router-dom";
import SubmissionsTable from "pages/AppPages/Shared/Submissions/components/SubmissionsTable";
import useSinglePatientSubmissionsDataColumns from "../../utils/useSinglePatientSubmissionsDataColumns";
import SubmissionsTableSearch from "pages/AppPages/Shared/Submissions/components/SubmissionsTableSearch";

const PatientSubmissions = () => {
  const { uuid } = useParams();
  const submissionDataColumns = useSinglePatientSubmissionsDataColumns();
  const { patientSubmissions } = useGetSubmissions({ patientSlug: uuid });
  const [filteredSubmissions, setFilteredSubmissions] =
    useState(patientSubmissions);
  const handleSearch = (searchedSubmissions) => {
    setFilteredSubmissions(searchedSubmissions);
  };
  const memoizedSubmissions = useMemo(
    () => filteredSubmissions,
    [filteredSubmissions]
  );
  const sortedSubmissions = memoizedSubmissions
    ?.slice()
    .sort((a, b) =>
      b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
    );
  useEffect(() => {
    setFilteredSubmissions(patientSubmissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useEffect(() => {
    if (patientSubmissions?.length !== 0 && !filteredSubmissions)
      setFilteredSubmissions(patientSubmissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSubmissions]);

  return (
    <>
      {patientSubmissions ? (
        <>
          <PageActionBar>
            <div id="patient-page-left" className="flex">
              {patientSubmissions.length > 0 && (
                <div className="patient-page-stats">
                  <SubmissionsTableSearch
                    data={patientSubmissions}
                    onChange={handleSearch}
                  />
                  <span className="patient-page-stats__wrapper">
                    <h4 className="patient-page-stats__count">
                      {filteredSubmissions?.length} /{" "}
                      <span className="patient-page-stats__total">
                        {patientSubmissions.length}
                      </span>
                    </h4>
                  </span>
                </div>
              )}
            </div>
          </PageActionBar>
          <SubmissionsTable
            data={sortedSubmissions}
            submissionDataColumns={submissionDataColumns}
            defaultBaseUrl="patients"
            sluggable={false}
            currentPatient={uuid}
          />
        </>
      ) : (
        <PageLoading vh50 />
      )}
    </>
  );
};

export default PatientSubmissions;
