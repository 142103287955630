import "pages/AppPages/Library/LibraryPage.styles.scss";
import Resource from "pages/AppPages/Library/components/Resource";
import AddLibraryResourceButton from "pages/AppPages/Library/components/AddLibraryResourceButton";
import LibraryEmptyState from "pages/AppPages/Library/components/LibraryEmptyState";
import React, { useState, useEffect, useMemo } from "react";
import {
  useGetTherapistLibrary,
  useGetPublicLibrary,
  useGetPublicFiles,
  useCurrentUser,
  useFetchLibrary,
  useGetPatients,
  useGetPatientLibrary,
  useFetchCollections,
  useGetCollections,
} from "utils/hooks";
import {
  PageActionBar,
  Modal,
  BottomModal,
  Divider,
  PageLoading,
} from "components";
import { BRAND_NAME } from "utils/constants";
import CollectionCard from "components/elements/CollectionCard";
import RemoveResourceConfirmationModal from "./RemoveResourceConfirmationModal";
import { useParams } from "react-router-dom";
import File from "pages/AppPages/TherapistPages/Resources/components/Files/components/File";
import RemoveFileConfirmationModal from "./RemoveFileConfirmationModal";
import AddFileButton from "pages/AppPages/TherapistPages/Resources/components/Files/components/AddFileButton";
import {
  filterTherapistResources,
  combineLinksAndFiles,
  filterCollections,
  getUniqueResources,
  getUniqueFiles,
  createTitleForModal,
  getCollectionImage,
} from "./utils";
import { FileResource, LibraryResource, Collection } from "types";
import AddLinksBody from "./AddLinksBody";
import AddFilesBody from "./AddFilesBody";

const PatientLibrary = () => {
  const { uuid } = useParams();
  const { patient } = useGetPatients({ slug: uuid });
  const { patientLibrary, patientFiles, therapistResources, therapistFiles } =
    useGetTherapistLibrary({
      patientSlug: patient.slug,
      withFiles: true,
    });
  const { publicPatientFiles, publicFiles } = useGetPublicFiles(patient?.uuid);
  const { fetchCollectionsByUser } = useFetchCollections();
  const { publicResources } = useGetPublicLibrary();
  const { collections, patientCollections, patientPublicCollections } =
    useGetCollections({ patientId: patient.uuid });
  const { patientPublicResources } = useGetPatientLibrary();
  const {
    fetchTherapistLibrary,
    fetchPublicPatientLibrary,
    fetchPublicLibrary,
  } = useFetchLibrary();
  const { isTherapist, user } = useCurrentUser();
  const [showResourceForm, setShowResourceForm] = useState(false);
  const [currentResource, setCurrentResource] =
    useState<LibraryResource | null>(null);
  const [currentCollection, setCurrentCollection] = useState<Collection | null>(
    null
  );
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [showFileForm, setShowFileForm] = useState(false);
  const [currentFile, setCurrentFile] = useState<FileResource | null>(null);
  const [isRemoveFileModalOpen, setIsRemovalFileModalOpen] = useState(false);
  const [therapistLinks, setTherapistLinks] = useState<LibraryResource[] | []>(
    filterTherapistResources(therapistResources, patient.uuid, collections)
  );
  const [filteredCollections, setFilteredCollections] = useState<
    Array<Collection> | []
  >(filterCollections(collections, patient.uuid));
  const [restPublicResources, setRestPublicResources] = useState<
    Array<LibraryResource> | []
  >(getUniqueResources(publicResources, patientPublicResources));
  const [selectedResources, setSelectedResources] = useState<any>([]);
  const [selectedCollections, setSelectedCollections] = useState<
    Array<Collection> | []
  >([]);

  const collResourcesIdsShared = useMemo(() => {
    const collectionsSharedToPatient = collections?.filter(
      (collection: Collection) => collection.sharedWith.includes(patient.uuid)
    );
    return collectionsSharedToPatient
      ?.map((collection: Collection) =>
        collection.collectionResources.map((r) => r.uuid)
      )
      .flat();
  }, [collections, patient]);

  const combinedResources = useMemo(() => {
    const links = patientLibrary?.filter(
      (item) => !collResourcesIdsShared?.includes(item.uuid)
    );
    return combineLinksAndFiles(links, patientFiles);
  }, [patientLibrary, patientFiles, collResourcesIdsShared]);

  const combinedPublicResources = useMemo(() => {
    const links = patientPublicResources?.filter(
      (item: FileResource) => !collResourcesIdsShared?.includes(item.uuid)
    );
    return combineLinksAndFiles(links, publicPatientFiles);
  }, [patientPublicResources, collResourcesIdsShared, publicPatientFiles]);

  const handleEditResource = (resource: LibraryResource) => {
    setCurrentResource(resource);
    setShowResourceForm(!showResourceForm);
  };

  const handleOpenRemovalModal = (resource: LibraryResource) => {
    setCurrentResource(resource);
    setIsRemovalModalOpen(true);
  };

  const handleOpenRemovalCollectionModal = ({ collection }: any) => {
    setCurrentCollection(collection);
    setIsRemovalModalOpen(true);
  };

  const handleCloseRemovalModal = () => {
    setCurrentResource(null);
    setIsRemovalModalOpen(false);
  };

  const handleEditFile = (file: FileResource) => {
    setCurrentFile(file);
    setShowFileForm(!showFileForm);
  };

  const handleOpenRemoveFileModal = (file: FileResource) => {
    setCurrentFile(file);
    setIsRemovalFileModalOpen(true);
  };

  const handleCloseRemoveFileModal = () => {
    setCurrentFile(null);
    setIsRemovalFileModalOpen(false);
  };

  const handleCloseSidePanel = () => {
    setShowResourceForm(false);
    setCurrentResource(null);
    setCurrentFile(null);
    setShowFileForm(false);
    setSelectedResources([]);
    setSelectedCollections([]);
  };

  useEffect(() => {
    fetchTherapistLibrary();
    fetchPublicLibrary();
    fetchPublicPatientLibrary(patient.uuid);
    fetchCollectionsByUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  useEffect(() => {
    setTherapistLinks(
      filterTherapistResources(therapistResources, patient.uuid, collections)
    );
    setFilteredCollections(filterCollections(collections, patient.uuid));
  }, [therapistResources, patient, collections]);

  useEffect(() => {
    setRestPublicResources(
      getUniqueResources(publicResources, patientPublicResources)
    );
  }, [patientPublicResources, publicResources]);

  return (
    <>
      {patientCollections &&
      patientPublicCollections &&
      combinedResources &&
      combinedPublicResources ? (
        <div className="library-page">
          <PageActionBar>
            <div className="patient-library-page-header">
              {isTherapist && (
                <div className="flex">
                  <AddLibraryResourceButton
                    title="Add link"
                    onClick={() => {
                      setShowResourceForm(!showResourceForm);
                    }}
                  />
                  <div className="ml-05">
                    <AddFileButton
                      title="Add file"
                      onClick={() => {
                        setShowFileForm(!showFileForm);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </PageActionBar>

          <div className="library-page-body library-page-condensed">
            <div className="library-page-section">
              {patientCollections?.length !== 0 &&
                patientCollections?.map((collection: Collection) => (
                  <CollectionCard
                    key={collection.uuid}
                    collection={collection}
                    title={collection.title}
                    items={collection.collectionResources.length}
                    imageSrc={String(getCollectionImage(collection))}
                    description={collection.description}
                    collectionId={collection.uuid}
                    showEdit={false}
                    showDelete={true}
                    onOpenDeleteModal={handleOpenRemovalCollectionModal}
                  />
                ))}
              {combinedResources?.length !== 0 &&
                combinedResources?.map((item) => {
                  if (item.type === "resource") {
                    return (
                      <Resource
                        resource={item}
                        key={item.uuid}
                        isOwner={item.addedBy === user?.id}
                        showEditIcon={false}
                        onEditResource={handleEditResource}
                        onOpenDeleteModal={handleOpenRemovalModal}
                      />
                    );
                  }
                  if (item.type === "file") {
                    return (
                      <File
                        file={item}
                        key={item.uuid}
                        isOwner={item.addedBy === user?.id}
                        showDeleteIcon={true}
                        handleFileEditClick={() => handleEditFile(item)}
                        handleFileDeleteClick={() =>
                          handleOpenRemoveFileModal(item)
                        }
                      />
                    );
                  }
                  return null;
                })}
            </div>
            {(patientPublicCollections?.length !== 0 ||
              combinedPublicResources?.length !== 0) && (
              <div className="divider_wrapper">
                <h3 className="brand_name">From {BRAND_NAME}</h3>
                <div className="divider_line">
                  <Divider />
                </div>
              </div>
            )}
            <div className="library-page-section">
              {patientPublicCollections?.length !== 0 &&
                patientPublicCollections?.map((collection: Collection) => (
                  <CollectionCard
                    key={collection.uuid}
                    collection={collection}
                    title={collection.title}
                    items={collection.collectionResources.length}
                    imageSrc={String(getCollectionImage(collection))}
                    description={collection.description}
                    collectionId={collection.uuid}
                    showEdit={false}
                    showDelete={true}
                    onOpenDeleteModal={handleOpenRemovalCollectionModal}
                  />
                ))}
              {combinedPublicResources?.length !== 0 &&
                combinedPublicResources?.map((item) => {
                  if (item.type === "resource") {
                    return (
                      <Resource
                        resource={item}
                        key={item.uuid}
                        isOwner={item.addedBy === user?.id}
                        showEditIcon={false}
                        onEditResource={handleEditResource}
                        onOpenDeleteModal={handleOpenRemovalModal}
                      />
                    );
                  }
                  if (item.type === "file") {
                    return (
                      <File
                        file={item}
                        key={item.uuid}
                        isOwner={item.addedBy === user?.id}
                        showDeleteIcon={true}
                        handleFileEditClick={() => handleEditFile(item)}
                        handleFileDeleteClick={() =>
                          handleOpenRemoveFileModal(item)
                        }
                      />
                    );
                  }
                  return null;
                })}

              {patientCollections?.length === 0 &&
                patientPublicCollections?.length === 0 &&
                combinedResources?.length === 0 &&
                combinedPublicResources?.length === 0 && <LibraryEmptyState />}
            </div>
          </div>
        </div>
      ) : (
        <PageLoading vh50 />
      )}

      {isTherapist && (
        <BottomModal
          isVisible={showResourceForm}
          handleClose={handleCloseSidePanel}
          title={createTitleForModal(patient, "Link")}
        >
          <AddLinksBody
            therapistLinks={therapistLinks}
            therapistCollections={filteredCollections}
            publicLinks={restPublicResources}
            selectedResources={selectedResources}
            setSelectedResources={setSelectedResources}
            selectedCollections={selectedCollections}
            setSelectedCollections={setSelectedCollections}
            handleCloseSidePanel={handleCloseSidePanel}
            currentResource={currentResource}
            setShowResourceForm={setShowResourceForm}
          />
        </BottomModal>
      )}
      {isTherapist && (
        <BottomModal
          isVisible={showFileForm}
          handleClose={handleCloseSidePanel}
          title={createTitleForModal(patient, "File")}
        >
          <AddFilesBody
            therapistFiles={getUniqueFiles(therapistFiles, patientFiles)}
            publicFiles={getUniqueFiles(publicFiles, publicPatientFiles)}
            selectedResources={selectedResources}
            setSelectedResources={setSelectedResources}
            handleEditFile={handleEditFile}
            handleOpenRemoveFileModal={handleOpenRemoveFileModal}
            handleCloseSidePanel={handleCloseSidePanel}
            currentFile={currentFile}
            setShowFileForm={setShowFileForm}
          />
        </BottomModal>
      )}

      {isTherapist && (
        <Modal isOpen={isRemovalModalOpen} onClose={handleCloseRemovalModal}>
          <RemoveResourceConfirmationModal
            resource={currentResource}
            collection={currentCollection}
            onCancel={handleCloseRemovalModal}
            patient={patient}
          />
        </Modal>
      )}
      {isTherapist && (
        <Modal
          isOpen={isRemoveFileModalOpen}
          onClose={handleCloseRemoveFileModal}
        >
          <RemoveFileConfirmationModal
            file={currentFile}
            onCancel={handleCloseRemoveFileModal}
            patient={patient}
          />
        </Modal>
      )}
    </>
  );
};

export default PatientLibrary;
