import "./PatientNotes.styles.scss";
import React, { ReactNode, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { PageActionBar, SidePanel, Modal, PageLoading } from "components";
import { useGetNotes, useGetPatients } from "utils/hooks";
import { useParams } from "react-router-dom";
import NotesTable from "./components/NotesTable";
import useNotesDataColumns from "./components/NotesTable/utils/useNotesDataColumns";
import AddNoteButton from "./components/AddNoteButton";
import PatientNotesEmptyState from "./components/PatientNotesEmptyState";
import NoteForm from "./components/NoteSidePanel/NoteForm";
import DeleteNoteConfirmationModal from "./components/NoteSidePanel/DeleteNoteConfirmationModal";
import { Note } from "types";

const PatientNotes = () => {
  const { uuid } = useParams();
  const { patient } = useGetPatients({ slug: uuid });
  const { patientNotes } = useGetNotes({ patientId: patient.uuid });
  const [showNotePanel, setShowNotePanel] = useState(false);
  const [currentNote, setCurrentNote] = useState<Note | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { t } = useTranslation("common");

  const handleOpenDeleteModal = (note: Note) => {
    setShowNotePanel(false);
    setCurrentNote(note);
    setIsDeleteModalOpen(true);
  };

  const notesDataColumns = useNotesDataColumns({
    handleOpenDeleteModal,
  });

  const handleViewNote = (note: Note) => {
    setCurrentNote(note);
    setShowNotePanel(true);
  };

  const handleClosePanel = () => {
    setShowNotePanel(false);
    setCurrentNote(null);
  };

  const handleAddNewNote = () => {
    setCurrentNote(null);
    setShowNotePanel(true);
  };

  const sortedNotes = patientNotes
    ?.slice()
    .sort(
      (a: Note, b: Note) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    );

  const PortaledModal = ({ component }: { component: ReactNode }) => {
    const modalRoot = document.getElementById("root") as HTMLElement;
    return ReactDOM.createPortal(component, modalRoot);
  };

  const handleNoteDeleted = () => {
    setIsDeleteModalOpen(false);
    handleClosePanel();
  };

  return (
    <>
      {sortedNotes ? (
        <>
          <PageActionBar>
            <AddNoteButton onClick={handleAddNewNote} />
          </PageActionBar>
          {sortedNotes.length > 0 ? (
            <NotesTable
              data={sortedNotes}
              notesDataColumns={notesDataColumns}
              defaultBaseUrl="patients"
              onRowClick={handleViewNote}
              onRowHover={(note) => setCurrentNote(note)}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />
          ) : (
            <PatientNotesEmptyState />
          )}
        </>
      ) : (
        <PageLoading vh50 />
      )}
      <SidePanel
        title={
          <div className="patient-workbook-panel-tabs">
            <h4 className="side-panel__header-title">
              {t(currentNote ? "notes.edit_note" : "notes.add_note")}
            </h4>
          </div>
        }
        isVisible={showNotePanel}
        onClose={handleClosePanel}
      >
        <NoteForm
          note={currentNote}
          onSuccess={handleClosePanel}
          patientId={patient.uuid}
        />
      </SidePanel>

      <PortaledModal
        component={
          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
          >
            <DeleteNoteConfirmationModal
              note={currentNote}
              onCancel={() => setIsDeleteModalOpen(false)}
              onDelete={handleNoteDeleted}
            />
          </Modal>
        }
      />
    </>
  );
};

export default PatientNotes;
