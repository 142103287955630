import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useGetPatients } from "utils/hooks";
import { User, Group, Assignment, Field } from "types";
import InfoSection from "./InfoSection";
import {
  displayFields,
  editFields,
} from "pages/AppPages/TherapistPages/AssignmentBuilder/components/AssignmentBuilderMenu/fieldTypes";
import HintAndError from "pages/AppPages/PatientPages/AssignmentsForPatients/fields/CommonComponents/HintAndError";
import {
  DisplayFields,
  EditableFields,
} from "pages/AppPages/PatientPages/AssignmentsForPatients/fields";
import { Button } from "components";
import { useAssignmentStore } from "pages/AppPages/PatientPages/AssignmentsForPatients/assignmentStore";
import {
  createSubmission,
  updateSubmission,
} from "pages/AppPages/PatientPages/AssignmentsForPatients/useSubmissionsApi";
import { useCurrentUser } from "utils/hooks";
import {
  updatePinVerificationFalse,
  updatePinVerificationTrue,
} from "store/actions/auth";

interface FillAssignmentProps {
  singlePatient: User | null;
  selectedPatient: { label: string; value: string } | null;
  group: Group | null;
  assignment: Assignment;
  hideFromPatient: boolean;
  onClose: () => void;
  requestPin: boolean;
}
const FillAssignment = ({
  singlePatient,
  selectedPatient,
  group,
  assignment,
  hideFromPatient,
  onClose,
  requestPin,
}: FillAssignmentProps) => {
  const { patient } = useGetPatients({ patientId: selectedPatient?.value });
  const { user } = useCurrentUser();
  const { state, dispatch } = useAssignmentStore();
  const reduxDispatch = useDispatch();
  const { activeSection } = state;
  const { t } = useTranslation("common");
  const [isWide, setIsWide] = useState(state.isWideData || false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const isFinalSection = state.isFinalSection();

  useEffect(() => {
    const addPinCheck = () => {
      reduxDispatch(
        requestPin ? updatePinVerificationFalse() : updatePinVerificationTrue()
      );
    };
    addEventListener("beforeunload", addPinCheck);
    return () => {
      removeEventListener("beforeunload", addPinCheck);
    };
  }, [reduxDispatch, requestPin]);

  useEffect(() => {
    const hasCheckboxGrid = activeSection?.fields?.some(
      (field) => field.type === "CHECKBOX_GRID"
    );
    const isAssessment = state?.activeAssignment?.category === "ASSESSMENT";
    if ((hasCheckboxGrid || isAssessment) && !isWide) {
      setIsWide(true);
      dispatch({ isWideData: true }); // Update state
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection, isWide, dispatch]);

  const saveSubmission = useCallback(
    async (isDraft: boolean) => {
      const responseFields = Object.keys(state.responses).map((key) => {
        const responseField = state.responses[key];
        // don't want to send preFilled to the server!! Also, this will remove
        // the preFilled indicator for the user as soon as they edit a field
        delete responseField.preFilled;
        return responseField;
      });

      //check if there's an existing draft
      if (!state.draftId) {
        return await createSubmission(
          {
            userId: singlePatient ? singlePatient.uuid : patient?.uuid,
            authorId: user ? user.id : "",
            assignmentId: state.activeAssignment?.uuid || "",
            assignmentLabel: state.activeAssignment?.label || "",
            privacy: state.isPrivate,
            groupPrivacy: state.isGroupPrivate,
            isDraft: isDraft,
            isWideData: isWide,
            hideFromPatient: hideFromPatient ? true : false,
          },
          responseFields
        );
      } else {
        return await updateSubmission(
          {
            userId: singlePatient ? singlePatient.uuid : patient?.uuid,
            authorId: user ? user.id : "",
            uuid: state.draftId,
            assignmentId: state.activeAssignment?.uuid || "",
            assignmentLabel: state.activeAssignment?.label || "",
            privacy: state.isPrivate,
            groupPrivacy: state.isGroupPrivate,
            isDraft: isDraft,
            isWideData: isWide,
            hideFromPatient: hideFromPatient ? true : false,
          },
          responseFields
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  const handleChange = (field: Field, index: number, value: any) => {
    if (!activeSection) return;
    state.responses[field.uuid as string].value = value;
    state.responses[field.uuid as string].preFilled = false;
    activeSection.fields[index].error = false;
    dispatch({ activeSection, responses: state.responses });
  };

  const handleSubmit = async (isDraft: boolean, btnName?: string) => {
    if (!activeSection) return;
    try {
      const { validatedSection, isValid } =
        state.validateSection(activeSection);
      dispatch({ activeSection: validatedSection, isSectionValid: isValid });
      if (!isValid) {
        toast.warning("Please fill out all required fields before proceeding");
        return;
      }
      setIsSubmitting(true);
      const submission = await saveSubmission(isDraft);
      dispatch({ activeDraft: submission });
      let hasSubmission = false;
      state.submissions.forEach((sub, index) => {
        if (sub.uuid === submission.uuid) {
          state.submissions[index] = submission;
          hasSubmission = true;
        }
      });
      if (!hasSubmission) {
        state.submissions.push(submission);
      }

      dispatch({ submissions: state.submissions });
      setIsSubmitting(false);

      if (isDraft) {
        toast(`Your ${state.activeAssignment?.label} draft has been saved!`);
      } else {
        toast(`Your ${state.activeAssignment?.label} has been submitted!`);
      }

      if (!isDraft) {
        onClose();
        dispatch({
          location: {
            assignmentSlug: null,
            sectionSlug: null,
          },
        });
        reduxDispatch(
          requestPin
            ? updatePinVerificationFalse()
            : updatePinVerificationTrue()
        );
      }

      if (btnName) {
        let section;
        if (btnName === "btn-next-section") section = state.getNextSection();
        if (btnName === "btn-prev-section")
          section = state.getPreviousSection();
        if (section) {
          dispatch({
            location: {
              assignmentSlug: assignment.slug,
              sectionSlug: section.slug,
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
      toast.warn(t("errors.something_went_wrong"));
    }
  };

  return (
    <div className="fill-modal_container">
      <InfoSection
        patient={singlePatient ? singlePatient : patient}
        group={group}
      />
      <div className="fill-modal_form">
        {activeSection?.description && (
          <div className="fill-modal_form_description">
            {activeSection.description}
          </div>
        )}
        {activeSection?.fields?.map((field, index) => {
          if (field.status === "HIDDEN") {
            return null;
          }
          if (displayFields?.includes(field.type)) {
            return (
              <div className={`fill-modal_form_field`} key={field.uuid}>
                <DisplayFields key={field.uuid} field={field} />
              </div>
            );
          }
          if (editFields?.includes(field.type)) {
            return (
              <div className={`fill-modal_form_field`} key={field.uuid}>
                <EditableFields
                  field={field}
                  defaultValue={state.responses[field.uuid as string]?.value}
                  onChange={(value) => handleChange(field, index, value)}
                />
                <HintAndError
                  error={field.error}
                  hint={field.hint && field.hint !== "null" ? field.hint : ""}
                />
              </div>
            );
          }
          return (
            <div className={`fill-modal_form_field`} key={field.uuid}>
              {field.label}
              <div>{field.value}</div>
            </div>
          );
        })}
        <div className="fill-modal_form_buttons">
          <Button
            value=""
            name="btn-prev-section"
            pill
            icon="ArrowLeftBold"
            extraClass="fill-modal_form_arrow"
            disabled={
              (activeSection?.order && activeSection.order === 1
                ? true
                : false) || isSubmitting
            }
            onClick={async () => {
              await handleSubmit(true, "btn-prev-section");
            }}
          />
          <Button
            value=""
            name="btn-next-section"
            pill
            icon="ArrowRightBold"
            extraClass="fill-modal_form_arrow"
            disabled={isFinalSection || isSubmitting}
            onClick={async () => await handleSubmit(true, "btn-next-section")}
          />
          <Button
            value="submit"
            name="btn-submit"
            pill
            extraClass="submit-button"
            disabled={!isFinalSection || isSubmitting}
            isLoading={isSubmitting}
            onClick={() => handleSubmit(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default FillAssignment;
