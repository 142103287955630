import { useEffect, useRef } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useFetchPractices from "./useFetchPractices";
import { Practice } from "types";

interface UseGetPracticesProps {
  practiceId?: string | null;
  slug?: string;
}

const useGetPractices = ({ practiceId, slug }: UseGetPracticesProps) => {
  const dataRef = useRef(false);
  const { fetchPractices } = useFetchPractices();

  const practices = useSelector(
    (state: RootStateOrAny) => state.practices.practices as Practice[]
  );

  useEffect(() => {
    if (dataRef.current) return;
    fetchPractices();
    dataRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const practice =
    practices?.filter((t: { uuid: string }) => t.uuid === practiceId)[0] ||
    practices?.filter((t: { slug: string }) => t.slug === slug)[0] ||
    null;

  return {
    practices: practices || [],
    practice,
  };
};

export default useGetPractices;
