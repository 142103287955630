import React, { useEffect, useRef, useState } from "react";
import SinglePatientPage from "../SinglePatient";
import SingleGroupPage from "../SingleGroup";
import {
  AssignmentDispatchContext,
  AssignmentStateContext,
  useAssignmentStoreDefaults,
} from "pages/AppPages/PatientPages/AssignmentsForPatients/assignmentStore";
import {
  getAssignments,
  getSubmissions,
} from "pages/AppPages/PatientPages/AssignmentsForPatients/useSubmissionsApi";
import { PageLoading } from "components";

interface Props {
  patient?: boolean;
  group?: boolean;
}

const AssignmentContextTherapistWrapper = ({ patient, group }: Props) => {
  const { assignmentState, setAssignmentState } = useAssignmentStoreDefaults();
  const ref = useRef(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (ref.current) return;
    try {
      const fetchData = async () => {
        if (assignmentState?.initialized) return;
        const submissions = await getSubmissions();
        const assignments = await getAssignments();
        setAssignmentState({
          assignments,
          submissions,
        });
      };
      fetchData().then(() => setIsDataLoaded(true));
      ref.current = true;
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AssignmentStateContext.Provider value={assignmentState}>
      <AssignmentDispatchContext.Provider value={setAssignmentState}>
        {isDataLoaded ? (
          <>
            {patient && <SinglePatientPage />}
            {group && <SingleGroupPage />}
          </>
        ) : (
          <PageLoading vh50 />
        )}
      </AssignmentDispatchContext.Provider>
    </AssignmentStateContext.Provider>
  );
};

export default AssignmentContextTherapistWrapper;
