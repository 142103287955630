import { FileResource, Template } from "types";

const convertSharedWithToRQLFormat = (patient: any) => {
  if (patient) {
    return `{ 
        patientId: "${patient?.value}"
      }`;
  }

  return;
};

export const formattedSharedWith = (selectedPatients: any) =>
  selectedPatients?.map(convertSharedWithToRQLFormat) || [];

export const filterFilesByTemplates = (
  files: Array<FileResource>,
  templates: Array<Template>
) => {
  if (!templates || templates.length === 0) return files;

  const templatesFileIds = [] as Array<string>;

  templates.map((template) => {
    template.content.map((section) => {
      section.fields.map((field) => {
        if (field.fileId) templatesFileIds.push(field.fileId);
      });
    });
  });

  return files.filter(
    (file) =>
      !file.fromBuilder ||
      (file.fromBuilder && !templatesFileIds.includes(file.uuid))
  );
};
