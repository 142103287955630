import "./PatientChooser.styles.scss";
import { Avatar, PatientsDropdown } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Patient } from "types";

interface PatientChooserProps {
  currentPatient: Patient;
  onChange: (patient: any) => void;
}

const PatientChooser = ({ currentPatient, onChange }: PatientChooserProps) => {
  const { t } = useTranslation(["common"]);
  const isFullNameNullable =
    currentPatient.firstName === null && currentPatient.lastName === null;
  const formattedCurrentPatient = {
    value: currentPatient.uuid,
    label: isFullNameNullable
      ? currentPatient.email
      : `${currentPatient.firstName} ${currentPatient.lastName}`,
  };

  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      padding: 15,
    }),
    control: () => ({
      width: "auto",
      display: "flex",
      cursor: "pointer",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      fontWeight: "bold",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  return (
    <div className="patient-chooser">
      <div className="patient-icon-wrapper">
        <Avatar
          firstName={currentPatient?.firstName}
          lastName={currentPatient?.lastName}
          user={currentPatient}
        />
      </div>
      <div className="patient-dropdown-wrapper">
        <PatientsDropdown
          name="patient-chooser"
          label={null}
          includeEmail={false}
          placeholder={t("patients.filter_by_patient")}
          defaultPatient={currentPatient}
          onChange={onChange}
          value={formattedCurrentPatient}
          isClearable={false}
          styles={customStyles}
          defaultToText={true}
          className="patient-chooser-select"
        />
      </div>
    </div>
  );
};

export default PatientChooser;
