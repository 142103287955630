import "./NoteDataCards.styles.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import PatientNotesEmptyState from "../PatientNotesEmptyState";
import { Button, IconButton } from "components";
import { toAmericanDateWithTimeFromUnix } from "utils/helpers";
import { Note } from "types";

interface NoteDataCardProps {
  note: Note;
  onClick: (note: Note) => void;
  handleOpenDeleteModal: (note: Note) => void;
}
interface NoteDataCardsProps {
  notes: Array<Note>;
  onClick: (note: Note) => void;
  handleOpenDeleteModal: (note: Note) => void;
}

const NoteDataCard = ({
  note,
  onClick,
  handleOpenDeleteModal,
}: NoteDataCardProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="note-data-card">
      <div className="note-data-card__top">
        <div>
          <p className="note-data-card__name">
            {toAmericanDateWithTimeFromUnix(note.createdAt)}
          </p>
          <p className="light-text">
            Last update: {toAmericanDateWithTimeFromUnix(note.updatedAt)}
          </p>
        </div>
        <div className="note-data-card__action-button flex align-center">
          <Button
            onClick={() => onClick(note)}
            name={`navigate_to_action_plan_button`}
            value="View Note"
            extraClass="secondary one-fifty-width"
            size="small"
          />
        </div>
      </div>
      <div className="note-data-card_snippet">{note.body}</div>
      <div className="note-data-card_buttons">
        <IconButton
          value={t("common_labels.remove")}
          icon="Remove"
          name="note_remove_btn"
          extraClass="note_delete_btn"
          onClick={() => handleOpenDeleteModal(note)}
        />
      </div>
    </div>
  );
};

const NoteDataCards = ({
  notes,
  onClick,
  handleOpenDeleteModal,
}: NoteDataCardsProps) => {
  return (
    <>
      {notes.length ? (
        <>
          {notes.map((note, i) => {
            return (
              <NoteDataCard
                note={note}
                key={i}
                onClick={onClick}
                handleOpenDeleteModal={handleOpenDeleteModal}
              />
            );
          })}
        </>
      ) : (
        <PatientNotesEmptyState />
      )}
    </>
  );
};

export default NoteDataCards;
