// import { Button, Divider, Modal } from "components";
import { Modal } from "components";
import React, { ReactNode, useState } from "react";
import ReactDOM from "react-dom";
import DeleteAssignmentConfirmationModal from "./DeleteAssignmentConfirmationModal";
import ReminderSettings from "./ReminderSettings/ReminderSettings";
import { Assignment } from "types";

interface AssignmentSettingsProps {
  assignment: Assignment;
  onClose?: any;
  hideNotificationSettings?: boolean;
  canSaveSettings?: boolean;
  setCanSaveSettings?: (data: boolean) => void;
}

const PortaledModal = ({ component }: { component: ReactNode }) => {
  const modalRoot = document.getElementById("root") as HTMLElement;

  return ReactDOM.createPortal(component, modalRoot);
};

const AssignmentSettings = ({
  assignment,
  onClose,
  hideNotificationSettings = false,
  canSaveSettings,
  setCanSaveSettings,
}: AssignmentSettingsProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleAssignmentDeleted = () => {
    setIsDeleteModalOpen(false);
    onClose();
  };

  return (
    <>
      <div>
        {assignment?.notification && !hideNotificationSettings && (
          <>
            <ReminderSettings
              entityId={assignment?.uuid}
              canSaveSettings={canSaveSettings}
              setCanSaveSettings={setCanSaveSettings}
            />
          </>
        )}
      </div>
      <PortaledModal
        component={
          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
          >
            <DeleteAssignmentConfirmationModal
              assignment={assignment}
              isForPatient={true}
              onCancel={() => setIsDeleteModalOpen(false)}
              onDelete={handleAssignmentDeleted}
            />
          </Modal>
        }
      />
    </>
  );
};

export default AssignmentSettings;
