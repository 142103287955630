import "./DeleteAssignmConfirmModal.styles.scss";
import React from "react";
import { Icon, Button } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";
import { useDeleteAssignment } from "utils/hooks";
import { Assignment } from "types";

interface DeleteAssignmentConfirmationModalProps {
  assignment: Assignment;
  onCancel: () => void;
  isForPatient?: boolean;
  onDelete?: () => void;
}

const DeleteAssignmentConfirmationModal = ({
  assignment,
  onCancel,
  isForPatient = false,
  onDelete,
}: DeleteAssignmentConfirmationModalProps) => {
  const { deleteAssignment } = useDeleteAssignment();

  return (
    <div className="assignment-deletion-confirmation">
      <div className="assignment-deletion-confirmation__icon">
        <Icon src={WarningIcon} />
      </div>
      <div className="assignment-deletion-confirmation__title">
        Are you sure you want to delete this {assignment?.label}?
      </div>
      <div className="assignment-deletion-confirmation__body">
        You won&apos;t be able to undo this action and your patient will no
        longer have access.
      </div>
      <div className="assignment-deletion-confirmation__buttons">
        <Button
          onClick={onCancel}
          name={`cancel_delete_assignment_button`}
          value="Never mind"
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => {
            deleteAssignment(assignment, isForPatient);
            onCancel();
            onDelete && onDelete();
          }}
          name={`confirm_delete_assignment_button`}
          value="Yes, delete it"
          extraClass="danger"
          size="small"
        />
      </div>
    </div>
  );
};

export default DeleteAssignmentConfirmationModal;
